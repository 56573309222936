var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Accordion from "react-bootstrap/Accordion";
import { StyledAccordion } from "./style";
var AccordionWrap = function (_a) {
    var defaultActiveKey = _a.defaultActiveKey, data = _a.data, layout = _a.layout, disableDefaultActive = _a.disableDefaultActive, props = __rest(_a, ["defaultActiveKey", "data", "layout", "disableDefaultActive"]);
    return (_jsx(StyledAccordion, __assign({ layout: layout }, props, { children: _jsx(Accordion, __assign({ defaultActiveKey: disableDefaultActive || defaultActiveKey || data[0].id }, { children: data === null || data === void 0 ? void 0 : data.map(function (item, idx) { return (_jsxs(Accordion.Item, __assign({ eventKey: idx }, { children: [_jsx(Accordion.Header, { children: item.title }), _jsx(Accordion.Body, { children: item.text })] }), idx)); }) })) })));
};
AccordionWrap.defaultProps = {
    layout: 1,
};
export default AccordionWrap;
