var faqs = [
    {
        title: "What is Businessflo?",
        text: "Businessflo is a comprehensive software solution designed to streamline and automate company processes, offering a seamless and efficient workflow management system."
    },
    {
        title: "What are the advantages of using Businessflo?",
        text: "Businessflo offers numerous advantages, including improved productivity, enhanced efficiency, reduced errors, better resource allocation, real-time insights, and optimized collaboration among teams."
    },
    {
        title: "How does Businessflo simplify company processes?",
        text: "Businessflo simplifies company processes by centralizing and automating various tasks, eliminating manual and repetitive activities, minimizing paperwork, providing real-time visibility into workflows, and enabling seamless communication and collaboration across departments."
    },
    {
        title: "Can Businessflo be customized to fit our specific business needs?",
        text: "Yes, Businessflo is highly customizable to adapt to your unique business requirements. The solution can be tailored to match your company's workflows, processes, and business rules, ensuring a perfect fit for your organization."
    },
    {
        title: "Is Businessflo suitable for businesses of all sizes?",
        text: "Yes, Businessflo caters to businesses of all sizes, from small startups to large enterprises. The solution is scalable and flexible, allowing organizations to easily adjust and accommodate their growth and evolving needs."
    },
    {
        title: "How secure is the data stored in Businessflo?",
        text: "Data security is a top priority for Align Business Systems. Businessflo employs robust security measures, including encryption, user access controls, regular backups, and adherence to industry best practices to ensure the confidentiality, integrity, and availability of your data."
    },
    {
        title: "Can Businessflo be accessed remotely or on mobile devices?",
        text: "Yes, Businessflo is designed to be accessible remotely and on various devices. The solution supports mobile access, enabling users to manage workflows, tasks, and approvals on-the-go, ensuring flexibility and convenience."
    },
    {
        title: "Does Align Business Systems provide training and support for Businessflo?",
        text: "Yes, Align Business Systems offers comprehensive training and ongoing support for Businessflo. Our team of experts will guide you through the implementation process, provide training sessions for your staff, and offer prompt assistance for any technical issues or titles that may arise."
    },
    {
        title: "How can we get started with Businessflo?",
        text: "To get started with Businessflo, simply reach out to our team through our website or contact details. We will be happy to discuss your requirements, provide a demo of the solution, and guide you through the next steps of implementing Businessflo for your organization."
    }
];
export default faqs;
