var pageHeader = {
    title: "Careers",
    path: "/careers"
};
var sectionOne = {
    title: "Join Our Team and <span>Shape the Future</span>",
    items: [
        {
            title: 'Full Stack Developer',
            subTitle: 'Full Time',
            text: "Good understanding of OOPs, Asp.net, MSSQL, React, Typescript, Crystal Report & Rest API\n            <br/>\n            Well-structured & optimized coding skills\n            <br/>\n            01+ year of experience is mandatory"
        },
        {
            title: 'ERP Consultant',
            subTitle: 'Full Time',
            text: "Good Communication Skills\n            <br/>\n            Knowledge of standard business processes of Service, trading and manufacturing companies\n            <br/>\n            Knowledge of basic accounting principles\n            <br/>\n            01+ year of experience is mandatory"
        },
        {
            title: 'Sales Executive',
            subTitle: 'Full Time',
            text: "Good Communication Skills\n            <br/>\n            Mastery of overall sales processes\n            <br/>\n            preferable Knowledge of ERP Systems\n            <br/>\n            Excellent in People Interaction & Sales\n            <br/>\n            Experience in Application/ERP Sales will be preffered\n            <br/>\n            Digital Marketing Skills will be preferred\n            <br/>\n            Good knowledge of relevant computer programs, such as CRM software, and telephone systems is essential."
        },
        {
            title: 'Freelance Sales Executive',
            subTitle: 'Full Time',
            text: "Good Communication Skills\n            <br/>\n            Digital Marketing Skills will be preferred\n            <br/>\n            Proven experience in freelance platforms like fiverr, upwork etc\n            <br/>\n            Strong understanding of freelance marketplaces, including knowledge of the bidding process, client acquisition, project management, and building long-term client relationships\n            <br/>\n            Strong portfolio and references"
        },
        {
            title: 'IT Intern',
            subTitle: 'Internship',
            text: "We are looking for a passionate candidate who is dedicated to his/her craft, takes pride in solving the problems and writing the prettier format of code, Knows well about the structure of the framework, and can hit the ground running.\n            <br/>\n            Under Graduate or Graduate in IT Related, 3 Months Internship Leading to Permanent Job."
        }
    ]
};
var contactSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    subTitle: "Connect with us to unlock a detailed analysis and assessment of your plan, opening up endless possibilities for your success.",
    contactTitle: 'SEND YOUR CV, NOW!',
    contactText: 'talent@alignbsystems.com',
    buttons: [
        {
            color: "secondary",
            hoverStyle: "three",
            path: "/contact-us",
            content: "Contact Us"
        },
    ]
};
export var careers = {
    pageHeader: pageHeader,
    sectionOne: sectionOne,
    contactSection: contactSection
};
export default careers;
