var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Image from "@/Components/UI/Image";
import { BrandLogoWrap } from "./style";
var ClientLogo = function (_a) {
    var path = _a.path, image = _a.image, hoverImage = _a.hoverImage, title = _a.title, layout = _a.layout, props = __rest(_a, ["path", "image", "hoverImage", "title", "layout"]);
    return (_jsx(BrandLogoWrap, __assign({ "$layout": layout }, props, { children: _jsxs("a", __assign({ href: path }, { children: [_jsx("div", __assign({ className: "brand-logo__image" }, { children: image && (_jsx(Image, { src: image, alt: title })) })), (layout === 1 || layout === 2) && (_jsx("div", __assign({ className: "brand-logo__image-hover" }, { children: hoverImage && (_jsx(Image, { src: hoverImage, alt: title })) })))] })) })));
};
ClientLogo.defaultProps = {
    layout: 1,
    path: "/",
    title: "Brand Logo",
};
export default ClientLogo;
