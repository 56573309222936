var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { themeGet, device } from "@/Theme";
export var StyledSection = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    padding-block-start: 56px;\n    padding-block-end: 56px;\n    background-color: ", ";\n    overflow: hidden;\n        padding-block-start: 70px;\n        padding-block-end: 70px;\n    }\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 100px;\n    }\n"], ["\n    position: relative;\n    padding-block-start: 56px;\n    padding-block-end: 56px;\n    background-color: ", ";\n    overflow: hidden;\n        padding-block-start: 70px;\n        padding-block-end: 70px;\n    }\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 100px;\n    }\n"])), themeGet("colors.primary"), device.large);
export var StyledBG = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    inset: 0;\n    left: 0 !important;\n    right: auto !important;\n    z-index: 0;\n    ", " {\n        inset: 0 0 0 auto;\n    }\n    & > div {\n        width: 100%;\n        height: 100%;\n        ", " {\n            width: auto;\n            height: auto;\n        }\n    }\n"], ["\n    position: absolute;\n    inset: 0;\n    left: 0 !important;\n    right: auto !important;\n    z-index: 0;\n    ", " {\n        inset: 0 0 0 auto;\n    }\n    & > div {\n        width: 100%;\n        height: 100%;\n        ", " {\n            width: auto;\n            height: auto;\n        }\n    }\n"])), device.small, device.small);
export var StyledButtonWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-block-start: 40px;\n    ", " {\n        margin-block-start: 30px;\n    }\n    .mt-btn {\n        margin-right: 15px;\n        margin-top: 15px;\n        color: ", ";\n        &:hover {\n            background-color: ", ";\n            border-color: ", ";\n        }\n    }\n"], ["\n    margin-block-start: 40px;\n    ", " {\n        margin-block-start: 30px;\n    }\n    .mt-btn {\n        margin-right: 15px;\n        margin-top: 15px;\n        color: ", ";\n        &:hover {\n            background-color: ", ";\n            border-color: ", ";\n        }\n    }\n"])), device.medium, themeGet("colors.white"), themeGet("colors.secondary"), themeGet("colors.secondary"));
export var ContentBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding-block-start: 30px;\n    padding-block-end: 30px;\n    z-index: 1;\n    position: relative;\n    text-align: center\n"], ["\n    padding-block-start: 30px;\n    padding-block-end: 30px;\n    z-index: 1;\n    position: relative;\n    text-align: center\n"])));
export var StyledHeading = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-weight: 600;\n    color: #fff;\n    position: relative;\n    line-height: 1.4;\n    margin-block-start: 15px;\n    span {\n        color: ", ";\n    }\n"], ["\n    font-weight: 600;\n    color: #fff;\n    position: relative;\n    line-height: 1.4;\n    margin-block-start: 15px;\n    span {\n        color: ", ";\n    }\n"])), themeGet("colors.secondary"));
export var StyledText = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-block-start: 10px;\n    font-size: 18px;\n    color: #fff;\n"], ["\n    margin-block-start: 10px;\n    font-size: 18px;\n    color: #fff;\n"])));
export var StyledInfoTitle = styled.h6(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 15px;\n    color: #fff;\n    text-transform: uppercase;\n    margin-block: 10px;\n"], ["\n    font-size: 15px;\n    color: #fff;\n    text-transform: uppercase;\n    margin-block: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
