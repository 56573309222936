var site = {
    titleTemplate: "Businessflo",
    description: "BusinessFlo is a cutting-edge and intelligent ERP solution designed to empower organizations to optimize their business processes. With its wide range of capabilities, BusinessFlo provides comprehensive support for businesses of all sizes and industries. From automating workflows to enhancing collaboration, BusinessFlo is the ultimate tool to streamline your operations and drive efficiency. Experience the power of BusinessFlo and unlock your business's true potential.",
    siteUrl: "https://businessflo.com/",
    image: "landing.png",
    siteLanguage: "en",
    copyright: "Align Business Systems. <a href='https://alignbsystems.com/' target='_blank' rel='noopener noreferrer'>All Rights Reserved.</a>",
    socials: [
        {
            title: "Youtube",
            icon: "fab fa-youtube",
            link: "https://www.youtube.com/@Businessflo4004",
        },
        {
            title: "Linkedin",
            icon: "fab fa-linkedin",
            link: "https://www.linkedin.com/company/align-business-systems-private-limited/",
        },
        {
            title: "Facebook",
            icon: "fab fa-facebook",
            link: "https://www.facebook.com/",
        },
    ],
    contact: {
        address: "Suite# 404, Imperial Trade Tower 68-C, \n        <br/> \n        7th Street Jami Commercial, Main Street 11, \n        <br/> \n        D.H.A. Phase 7 Karachi, 75500",
        website: "https://businessflo.com/",
        support: {
            email: "support@alignbsystems.com",
            phones: ["+92.318.6944418"],
        },
        sales: {
            email: "sales@alignbsystems.com",
            phones: ["+92.317.3822207", "+92.317.3822206"],
        }
    },
};
export default site;
