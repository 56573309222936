var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { themeGet, device } from "@/Theme";
export var BoxIconInner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-radius: ", ";\n    transition: ", ";\n    text-align: center;\n    display: flex;\n    align-items: center;\n    padding-block-start: 25px;\n    padding-block-end: 15px;\n    padding-inline: 15px;\n    height: 100%;\n    \n    ", " {\n        padding-block: 30px;\n        padding-inline: 15px;\n    }\n"], ["\n    border-radius: ", ";\n    transition: ", ";\n    text-align: center;\n    display: flex;\n    align-items: center;\n    padding-block-start: 25px;\n    padding-block-end: 15px;\n    padding-inline: 15px;\n    height: 100%;\n    \n    ", " {\n        padding-block: 30px;\n        padding-inline: 15px;\n    }\n"])), themeGet("radii.md"), themeGet("transition"), device.medium);
export var BoxIconImg = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    max-width: 80px;\n    flex-basis: 80px;\n    margin-inline-end: 10px;\n    color: ", ";\n"], ["\n    max-width: 80px;\n    flex-basis: 80px;\n    margin-inline-end: 10px;\n    color: ", ";\n"])), themeGet("colors.heading"));
export var BoxIconContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    text-align: start;\n    max-width: calc(100% - 90px);\n    flex-basis: calc(100% - 90px);\n"], ["\n    text-align: start;\n    max-width: calc(100% - 90px);\n    flex-basis: calc(100% - 90px);\n"])));
export var BoxIcontitle = styled.h5(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: 500;\n    line-height: 1.25;\n"], ["\n    font-weight: 500;\n    line-height: 1.25;\n"])));
export var BoxIconText = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-block-start: 12px;\n    margin-block-end: 0;\n"], ["\n    margin-block-start: 12px;\n    margin-block-end: 0;\n"])));
export var BoxIconBtn = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-block-start: 20px;\n"], ["\n    margin-block-start: 20px;\n"])));
export var BoxIconWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    height: 100%;\n\n    &:hover {\n        ", " {\n            border-color: #fff;\n            transform: translateY(-5px);\n            background: #fff;\n            box-shadow: ", ";\n        }\n    }\n"], ["\n    height: 100%;\n\n    &:hover {\n        ", " {\n            border-color: #fff;\n            transform: translateY(-5px);\n            background: #fff;\n            box-shadow: ", ";\n        }\n    }\n"])), BoxIconInner, themeGet("shadows.default"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
