var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "@/Theme";
import Anchor from "@/Components/UI/Anchor";
export var StyledMobileMenu = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var StyledNavbar = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex-direction: column;\n"], ["\n    flex-direction: column;\n"])));
export var StyledNavitem = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: relative;\n    border-bottom: 1px solid rgba(255, 255, 255, 0.15);\n    &:hover {\n        & > a {\n            color: #fff;\n        }\n    }\n    &.submenu-open {\n        & > .submenu,\n        & > .megamenu {\n            max-height: 1000px;\n            visibility: visible;\n            opacity: 1;\n            padding-block-start: 12px;\n            padding-block-end: 10px;\n            pointer-events: visible;\n        }\n    }\n    ", "\n"], ["\n    position: relative;\n    border-bottom: 1px solid rgba(255, 255, 255, 0.15);\n    &:hover {\n        & > a {\n            color: #fff;\n        }\n    }\n    &.submenu-open {\n        & > .submenu,\n        & > .megamenu {\n            max-height: 1000px;\n            visibility: visible;\n            opacity: 1;\n            padding-block-start: 12px;\n            padding-block-end: 10px;\n            pointer-events: visible;\n        }\n    }\n    ", "\n"])), function (_a) {
    var $inSubmenu = _a.$inSubmenu;
    return $inSubmenu && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            &:last-of-type {\n                border: none;\n            }\n        "], ["\n            &:last-of-type {\n                border: none;\n            }\n        "])));
});
export var StyledButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: absolute;\n    right: 0;\n    top: 12px;\n    width: 40px;\n    height: 40px;\n    background: rgba(255, 255, 255, 0.1);\n    color: #fff;\n    text-align: center;\n    line-height: 40px;\n    cursor: pointer;\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    border: none;\n    padding: 0;\n    &:hover {\n        i {\n            color: #fff;\n        }\n    }\n    ", "\n"], ["\n    position: absolute;\n    right: 0;\n    top: 12px;\n    width: 40px;\n    height: 40px;\n    background: rgba(255, 255, 255, 0.1);\n    color: #fff;\n    text-align: center;\n    line-height: 40px;\n    cursor: pointer;\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    border: none;\n    padding: 0;\n    &:hover {\n        i {\n            color: #fff;\n        }\n    }\n    ", "\n"])), function (_a) {
    var $inSubmenu = _a.$inSubmenu;
    return $inSubmenu && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            top: 50%;\n            transform: translateY(-50%);\n            width: 30px;\n            height: 30px;\n            line-height: 30px;\n            right: 0px;\n        "], ["\n            top: 50%;\n            transform: translateY(-50%);\n            width: 30px;\n            height: 30px;\n            line-height: 30px;\n            right: 0px;\n        "])));
});
export var StyledNavlink = styled(Anchor)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: block;\n    color: #ffffff;\n    padding: 18px 0;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 1.5;\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    span {\n        padding: 0;\n    }\n    ", "\n"], ["\n    display: block;\n    color: #ffffff;\n    padding: 18px 0;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 1.5;\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    span {\n        padding: 0;\n    }\n    ", "\n"])), function (_a) {
    var $inSubmenu = _a.$inSubmenu;
    return $inSubmenu && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n            font-size: 15px;\n            color: rgba(255, 255, 255, 0.7);\n            font-weight: 500;\n            padding: 10px 0px;\n        "], ["\n            font-size: 15px;\n            color: rgba(255, 255, 255, 0.7);\n            font-weight: 500;\n            padding: 10px 0px;\n        "])));
});
var submenuStyles = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    position: relative;\n    min-width: 100%;\n    padding-block-start: 0;\n    padding-block-end: 0;\n    padding-inline-start: 14px;\n    padding-inline-end: 0;\n    background-color: transparent;\n    top: auto;\n    left: 0;\n    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);\n    border-bottom: none;\n    transform: translateY(0);\n    max-height: 0;\n    overflow-y: hidden;\n    transition-property: all;\n    transition-duration: 0.5s;\n    transition-timing-function: ease-in-out;\n    transform-origin: top;\n    z-index: 99;\n"], ["\n    position: relative;\n    min-width: 100%;\n    padding-block-start: 0;\n    padding-block-end: 0;\n    padding-inline-start: 14px;\n    padding-inline-end: 0;\n    background-color: transparent;\n    top: auto;\n    left: 0;\n    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);\n    border-bottom: none;\n    transform: translateY(0);\n    max-height: 0;\n    overflow-y: hidden;\n    transition-property: all;\n    transition-duration: 0.5s;\n    transition-timing-function: ease-in-out;\n    transform-origin: top;\n    z-index: 99;\n"])));
export var StyledSubmenu = styled.ul(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    ", ";\n"], ["\n    ", ";\n"])), submenuStyles);
export var StyledMegamenu = styled.ul(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    ", ";\n"], ["\n    ", ";\n"])), submenuStyles);
export var StyledMegatitle = styled.h2(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    padding: 10px 0px;\n    position: relative;\n    line-height: 2;\n    color: rgb(255, 255, 255);\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-bottom: 0px;\n"], ["\n    padding: 10px 0px;\n    position: relative;\n    line-height: 2;\n    color: rgb(255, 255, 255);\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-bottom: 0px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
