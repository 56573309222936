var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { themeGet, space, border, color } from "@/Theme";
export var TestimonialWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: #fff;\n    border-radius: ", ";\n    transition: ", ";\n    padding-block-start: 51px;\n    padding-block-end: 40px;\n    padding-inline: 37px;\n    ", ";\n    ", ";\n    ", ";\n"], ["\n    background: #fff;\n    border-radius: ", ";\n    transition: ", ";\n    padding-block-start: 51px;\n    padding-block-end: 40px;\n    padding-inline: 37px;\n    ", ";\n    ", ";\n    ", ";\n"])), themeGet("radii.md"), themeGet("transition"), color, space, border);
export var TestimonialSubject = styled.h6(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-block-end: 15px;\n"], ["\n    margin-block-end: 15px;\n"])));
export var TestimonialReview = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 18px;\n    color: #575757;\n"], ["\n    font-size: 18px;\n    color: #575757;\n"])));
export var AuthorInfoWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    margin-block-start: 20px;\n"], ["\n    display: flex;\n    align-items: center;\n    margin-block-start: 20px;\n"])));
export var AuthorMedia = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex-shrink: 0;\n    margin-inline-end: 30px;\n    width: 70px;\n    height: 70px;\n\n    img {\n        border-radius: ", ";\n    }\n"], ["\n    flex-shrink: 0;\n    margin-inline-end: 30px;\n    width: 70px;\n    height: 70px;\n\n    img {\n        border-radius: ", ";\n    }\n"])), themeGet("radii.round"));
export var AuthorInfo = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    flex-grow: 1;\n"], ["\n    flex-grow: 1;\n"])));
export var AuthorName = styled.h6(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var AuthorRole = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: block;\n    font-size: 14px;\n    &:before {\n        content: \" / \";\n        padding: 0 5px;\n    }\n"], ["\n    display: block;\n    font-size: 14px;\n    &:before {\n        content: \" / \";\n        padding: 0 5px;\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
