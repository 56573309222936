var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { device } from "@/Theme";
export var PageHeaderWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    isolation: isolate;\n    overflow: hidden;\n    padding-block-start: 60px;\n    padding-block-end: 60px;\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 100px;\n    }\n    ", " {\n        padding-block-start: 192px;\n        padding-block-end: 165px;\n    }\n"], ["\n    position: relative;\n    isolation: isolate;\n    overflow: hidden;\n    padding-block-start: 60px;\n    padding-block-end: 60px;\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 100px;\n    }\n    ", " {\n        padding-block-start: 192px;\n        padding-block-end: 165px;\n    }\n"])), device.medium, device.large);
export var StyledBG = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    inset: 0;\n    z-index: -1;\n    & > div {\n        height: 100%;\n        width: 100%;\n    }\n"], ["\n    position: absolute;\n    inset: 0;\n    z-index: -1;\n    & > div {\n        height: 100%;\n        width: 100%;\n    }\n"])));
export var StyledTitle = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: #fff;\n    margin-block-end: 15px;\n"], ["\n    color: #fff;\n    margin-block-end: 15px;\n"])));
export var StyledDesc = styled.h5(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: #fff;\n    font-weight: 400;\n"], ["\n    color: #fff;\n    font-weight: 400;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
