var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, device, themeGet } from "@/Theme";
export var OffCanvasWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    background-color: ", ";\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    z-index: 9999;\n    background: rgba(0, 0, 0, 0.7);\n    cursor: url(", ") 16 16, pointer;\n    overflow-y: hidden;\n    visibility: hidden;\n    opacity: 0;\n    ", "\n    ", "\n"], ["\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    background-color: ", ";\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    z-index: 9999;\n    background: rgba(0, 0, 0, 0.7);\n    cursor: url(", ") 16 16, pointer;\n    overflow-y: hidden;\n    visibility: hidden;\n    opacity: 0;\n    ", "\n    ", "\n"])), themeGet("colors.heading"), require("@/Assets/Images/Icons/light-close.png").default, function (props) {
    return props.isOpen && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            visibility: visible;\n            opacity: 1;\n        "], ["\n            visibility: visible;\n            opacity: 1;\n        "])));
}, function (props) {
    return props.scrollable && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            overflow: auto;\n        "], ["\n            overflow: auto;\n        "])));
});
export var OffCanvasInner = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    transform: translateX(120%);\n    width: 300px;\n    height: 100%;\n    margin-inline-start: auto;\n    cursor: default;\n    background: ", ";\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    overflow-y: hidden;\n    ", " {\n        width: 400px;\n    }\n    ", "\n    ", "\n"], ["\n    transform: translateX(120%);\n    width: 300px;\n    height: 100%;\n    margin-inline-start: auto;\n    cursor: default;\n    background: ", ";\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    overflow-y: hidden;\n    ", " {\n        width: 400px;\n    }\n    ", "\n    ", "\n"])), themeGet("colors.primary"), device.small, function (props) {
    return props.scrollable && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            overflow-y: auto;\n        "], ["\n            overflow-y: auto;\n        "])));
}, function (props) {
    return props.isOpen && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            transform: translateX(0%);\n        "], ["\n            transform: translateX(0%);\n        "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
