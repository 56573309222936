var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import styled, { space, device } from "@/Theme";
export var FooterWrap = styled.footer(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #fff;\n"], ["\n    background-color: #fff;\n"])));
export var FooterTop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding-block-start: 40px;\n    padding-block-end: 40px;\n    ", " {\n        padding-block-start: 60px;\n        padding-block-end: 60px;\n    }\n    ", " {\n        padding-block-start: 80px;\n        padding-block-end: 80px;\n    }\n"], ["\n    padding-block-start: 40px;\n    padding-block-end: 40px;\n    ", " {\n        padding-block-start: 60px;\n        padding-block-end: 60px;\n    }\n    ", " {\n        padding-block-start: 80px;\n        padding-block-end: 80px;\n    }\n"])), device.medium, device.large);
export var FooterBottom = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding-block-end: 30px;\n"], ["\n    padding-block-end: 30px;\n"])));
export var FooterWidget = styled(function (_a) {
    var mt = _a.mt, mb = _a.mb, ml = _a.ml, mr = _a.mr, pt = _a.pt, pb = _a.pb, pl = _a.pl, pr = _a.pr, rest = __rest(_a, ["mt", "mb", "ml", "mr", "pt", "pb", "pl", "pr"]);
    return _jsx("div", __assign({}, rest));
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", ";\n"], ["\n    ", ";\n"])), space);
export var LogoWidget = styled.figure(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-block-end: 30px;\n"], ["\n    margin-block-end: 30px;\n"])));
export var TextWidget = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var FooterWidgetList = styled.ul(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    li {\n        &:not(:last-child) {\n            margin-block-end: 10px;\n        }\n    }\n"], ["\n    li {\n        &:not(:last-child) {\n            margin-block-end: 10px;\n        }\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
