var modules = {
    title: "Modules",
    subTitle: "Comprehensive Modules for <span>Streamlined Operations</span>",
    items: [
        {
            icon: "Icons/sales-management.png",
            title: "Sales Management",
        },
        {
            icon: "Icons/service-management.png",
            title: "Service Management",
        },
        {
            icon: "Icons/property-management.png",
            title: "Property Management",
        },
        {
            icon: "Icons/finance.png",
            title: "Finance",
        },
        {
            icon: "Icons/hr-management.png",
            title: "Human Resource Management",
        },
        {
            icon: "Icons/purchase-management.png",
            title: "Purchase Management",
        },
        {
            icon: "Icons/production.png",
            title: "Production",
        },
        {
            icon: "Icons/inventory-management.png",
            title: "Inventory Management",
        },
        {
            icon: "Icons/plant-maintenance.png",
            title: "Plant Maintenance",
        },
    ]
};
export default modules;
