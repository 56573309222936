var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyledMainMenu, StyledNavbar, StyledNavitem, StyledNavlink, StyledSubmenu, StyledMegamenu, StyledMegaTitle, } from "./style";
var MainMenu = function (_a) {
    var menuData = _a.menuData, color = _a.color, space = _a.space, vSpace = _a.vSpace, bottomLine = _a.bottomLine, alignment = _a.alignment, props = __rest(_a, ["menuData", "color", "space", "vSpace", "bottomLine", "alignment"]);
    return (_jsx(StyledMainMenu, __assign({ "$alignment": alignment }, props, { children: _jsx(StyledNavbar, { children: menuData === null || menuData === void 0 ? void 0 : menuData.map(function (menu) {
                var submenu = menu.subMenu ? menu.subMenu : null;
                var megamenu = menu.megaMenu ? menu.megaMenu : null;
                return (_jsxs(StyledNavitem, __assign({ className: "nav-item", hasSubmenu: submenu, hasMegamenu: megamenu, "$space": space, "$alignment": alignment }, { children: [_jsxs(StyledNavlink, __assign({ className: "nav-link", path: menu.path, hassubmenu: submenu || megamenu ? "true" : "false", "$color": color, "$vSpace": vSpace, "$bottomLine": bottomLine }, { children: [_jsx("span", { children: menu.text }), (submenu || megamenu) && (_jsx("i", { className: "icon fa fa-angle-down" }))] })), submenu && (_jsx(StyledSubmenu, __assign({ className: "submenu" }, { children: submenu.map(function (subitem, i) {
                                var hasSubmenuLevelTwo = subitem.isSubmenu;
                                var submenuLevelTwo = subitem.submenu;
                                return (_jsxs(StyledNavitem, __assign({ className: "nav-item", "$subitem": true }, { children: [_jsxs(StyledNavlink, __assign({ className: "nav-link", path: subitem.path, "$bottomLine": bottomLine, "$sublink": true }, { children: [_jsx("span", { children: subitem.text }), hasSubmenuLevelTwo && (_jsx("i", { className: "icon fa fa-angle-down" }))] })), submenuLevelTwo && (_jsx(StyledSubmenu, { children: submenuLevelTwo.map(function (subitemLevelTwo, j) {
                                                return (_jsx(StyledNavitem, __assign({ "$subitem": true }, { children: _jsx(StyledNavlink, __assign({ path: subitemLevelTwo.path, "$sublink": true, "$bottomLine": bottomLine }, { children: subitemLevelTwo.text })) }), "submenu-".concat(menu.id, "-").concat(i, "-").concat(j)));
                                            }) }))] }), "submenu-".concat(menu.id, "-").concat(i)));
                            }) }))), megamenu && (_jsx(StyledMegamenu, __assign({ className: "megamenu" }, { children: megamenu.map(function (megaitem, i) {
                                var megaSubmenu = megaitem.submenu;
                                return (_jsxs("li", { children: [_jsx(StyledMegaTitle, { children: megaitem.title }), megaSubmenu && (_jsx("ul", { children: megaSubmenu.map(function (megaSubitem, j) {
                                                return (_jsx(StyledNavitem, __assign({ "$megitem": true }, { children: _jsx(StyledNavlink, __assign({ path: megaSubitem.path, "$bottomLine": bottomLine, "$sublink": true }, { children: _jsx("span", { children: megaSubitem.text }) })) }), "megasubmenu-".concat(i, "-").concat(j)));
                                            }) }))] }, "megamenu-".concat(menu.id, "-").concat(i)));
                            }) })))] }), "mainmenu-".concat(menu.id)));
            }) }) })));
};
MainMenu.defaultProps = {
    alignment: "center",
    color: "dark",
    space: 1,
    vSpace: 1,
    bottomLine: true,
};
export default MainMenu;
