var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import TeamMember from "@/Components/TeamMember";
import { TeamWrapper, StyledHeading } from "./style";
var TeamMemberSectionTwo = function (_a) {
    var data = _a.data;
    var title = data.title, items = data.items;
    return (_jsx(TeamWrapper, { children: _jsxs(Container, { children: [_jsx(Row, { children: _jsx(Col, __assign({ lg: 12 }, { children: _jsx(StyledHeading, { dangerouslySetInnerHTML: { __html: title } }) })) }), _jsx(Row, __assign({ className: "justify-content-center" }, { children: items &&
                        items.map(function (item, idx) { return (_jsx(Col, __assign({ lg: 3, md: 6, mb: "30px" }, { children: _jsx(TeamMember, { layout: 3, image: item.image, name: item.title, designation: item.text }) }), "executive-".concat(idx))); }) }))] }) }));
};
export default TeamMemberSectionTwo;
