var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import styled, { css, typography, space, color, layout, flexbox, position, themeGet, } from "@/Theme";
import { allowedTags } from "@/Utils";
export var StyledHeading = styled("h2").withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return !__spreadArray([], __read(allowedTags), false).includes(prop) && defaultValidatorFn(prop);
    },
})(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    ", "\n    ", "\n    \n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n\n    ", "\n"], ["\n    ", "\n    ", "\n    \n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n\n    ", "\n"])), function (_a) {
    var $tt = _a.$tt;
    return $tt && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            text-transform: ", ";\n        "], ["\n            text-transform: ", ";\n        "])), $tt);
}, function (_a) {
    var $ww = _a.$ww;
    return $ww && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            word-wrap: ", ";\n        "], ["\n            word-wrap: ", ";\n        "])), $ww);
}, typography, space, color, layout, flexbox, position, function (props) {
    return props.$child && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n            & > * {\n                ", "\n                ", "\n            ", "\n            }\n        "], ["\n            & > * {\n                ", "\n                ", "\n            ", "\n            }\n        "])), function (props) {
        return props.$child.color === "primary" && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n                        color: ", ";\n                    "], ["\n                        color: ", ";\n                    "])), themeGet("colors.primary"));
    }, function (props) {
        return props.$child.color === "secondary" && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n                        color: ", ";\n                    "], ["\n                        color: ", ";\n                    "])), themeGet("colors.secondary"));
    }, function (props) {
        return (props.$child.color !== "primary") &&
            (props.$child.color !== "secondary") && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n                        color: ", ";\n                    "], ["\n                        color: ", ";\n                    "])), function (props) {
            return props.$child.color || themeGet("colors.secondary");
        });
    });
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
