import Home from "@/Pages/Home";
import About from "@/Pages/About";
import SolutionHighlights from "@/Pages/SolutionHighlights";
import Features from "@/Pages/Features";
import FAQs from "@/Pages/FAQs";
import Advisors from "@/Pages/Advisors";
import Partners from "@/Pages/Partners";
import Clients from "@/Pages/Clients";
import Events from "@/Pages/Events";
import Careers from "@/Pages/Careers";
import Contact from "@/Pages/Contact";
export var ROUTES = [
    {
        path: "/",
        component: Home,
        exact: true
    },
    {
        path: "/about-us",
        component: About
    },
    {
        path: "/solution-highlights",
        component: SolutionHighlights
    },
    {
        path: "/features",
        component: Features
    },
    {
        path: "/faqs",
        component: FAQs
    },
    {
        path: "/partners",
        component: Partners
    },
    {
        path: "/advisors",
        component: Advisors
    },
    {
        path: "/clients",
        component: Clients
    },
    {
        path: "/events",
        component: Events
    },
    {
        path: "/careers",
        component: Careers
    },
    {
        path: "/contact-us",
        component: Contact
    },
];
