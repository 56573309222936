var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import SwiperSlider, { SwiperSlide } from "@/Components/UI/Swiper";
import ClientLogo from "@/Components/UI/ClientLogo";
import { Container } from "@/Components/UI/Wrapper";
import { SectionWrap } from "./style";
import { clients } from "@/Data";
var slider = {
    slidesPerView: 6,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: true,
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        575: {
            slidesPerView: 3,
        },
        767: {
            slidesPerView: 4,
        },
        991: {
            slidesPerView: 5,
        },
        1499: {
            slidesPerView: 6,
        },
    },
    navigation: true
};
var prevNextOptions = {
    prevIcon: "fa-solid fa-chevron-left",
    nextIcon: "fa-solid fa-chevron-right",
};
var ClientAreaOne = function () {
    return (_jsx(SectionWrap, { children: _jsx(Container, { children: _jsx(SwiperSlider, __assign({ options: slider, prevIcon: prevNextOptions.prevIcon, nextIcon: prevNextOptions.nextIcon, vAlign: "center" }, { children: clients.map(function (client, i) {
                    return (_jsx(SwiperSlide, __assign({ className: "item" }, { children: _jsx(ClientLogo, { image: client.image, hoverImage: client.hoverImage }) }), i));
                }) })) }) }));
};
export default ClientAreaOne;
