var pageHeader = {
    image: "Banner/banner-02.png",
    title: "Partners",
    text: '"Strong Partnerships are the foundation of success. At Align Business Systems, we believe in building collaborative relationships that empower growth, innovation, and mutual success. Together, we can create limitless possibilities and achieve extraordinary results."'
};
var sectionOne = {
    title: "Discover Our <span>Partners Network<span>",
    text: "Explore our Partners Network, a diverse ecosystem of trusted companies and organizations that collaborate with Align Business Systems. Our Partners represent a range of industries and specialties, bringing unique expertise and innovative solutions to the table. By joining forces with our Partners, we leverage their capabilities, industry insights, and cutting-edge technologies to deliver exceptional results for our clients. Together, we create a dynamic network that fosters collaboration, drives innovation, and unlocks new opportunities for growth and success. Join our Associate Network and be part of a thriving community dedicated to transforming businesses and shaping the future.",
    items: [
        {
            title: "Xpedite Technology Solutions",
            text: "At Xpedite Technology Solutions we are the team of experts delivering fast and satisfactory solutions to resolve clients issues. We listen carefully to our customers\u2019 needs and act in the best way possible.\n            Our team is skilled in solving complex business problems using the most suitable and relevant technology. We have expertise in Microsoft Dynamics, Power Platform, 365, BI, Data warehousing and Custom Mobile & Web App development.\n            We can also assist organizations in infrastructure and security related solutions.",
            image: 'Partners/xpeditesol.png'
        }
    ],
    buttons: [
        {
            path: "/contact-us",
            content: "Contact Us"
        },
        {
            path: "/about-us",
            content: "Learn More",
            variant: "texted",
            hoverStyle: "two",
        }
    ]
};
var sectionTwo = {
    title: "Partnership Benefits",
    subTitle: "Unlock the <span>Power of Partnership</span>",
    items: [
        {
            title: "Expanded Market Reach",
            text: "Joining forces with Align Business Systems opens doors to new markets and customer segments, allowing you to expand your reach and increase brand visibility.",
            icon: "Icons/market-reach.png"
        },
        {
            title: "Co-Marketing Opportunities",
            text: "Benefit from collaborative marketing initiatives, joint campaigns, and co-branded materials that leverage the strengths of both your business and Align Business Systems, amplifying your marketing efforts.",
            icon: "Icons/marketing.png"
        },
        {
            title: "Access to Cutting-Edge Technology",
            text: "Gain access to our advanced technology stack, including state-of-the-art solutions such as React, ASP.NET, TypeScript, SQL Server, and Crystal Reports, empowering you to deliver innovative and robust solutions to your clients.",
            icon: "Icons/technology.png"
        },
        {
            title: "Comprehensive Training and Support",
            text: "Take advantage of our comprehensive training programs and dedicated support resources. We provide you with the knowledge, tools, and assistance you need to excel in implementing and supporting our solutions.",
            icon: "Icons/support.png"
        },
        {
            title: "Joint Business Development",
            text: "Collaborate with Align Business Systems on business development initiatives, joint proposals, and strategic planning. Leverage our industry expertise and resources to identify new opportunities and accelerate your growth.",
            icon: "Icons/business-development.png"
        },
        {
            title: "Enhanced Competitive Advantage",
            text: "Stand out in the market by offering a complete suite of solutions backed by Align Business Systems' reputation as a leading ERP provider. Access our knowledge base and expertise to differentiate your business and win more projects.",
            icon: "Icons/advantage.png"
        },
    ]
};
var callToActionSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var Partners = {
    pageHeader: pageHeader,
    sectionOne: sectionOne,
    sectionTwo: sectionTwo,
    callToActionSection: callToActionSection
};
export default Partners;
