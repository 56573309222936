var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TransitionGroup, Transition as ReactTransition, } from "react-transition-group";
var timeout = 500;
var getTransitionStyles = {
    entering: {
        position: "absolute",
        opacity: 0,
    },
    entered: {
        transition: "opacity ".concat(timeout, "ms ease-in-out"),
        opacity: 1,
    },
    exiting: {
        transition: "all ".concat(timeout, "ms ease-in-out"),
        opacity: 0,
    },
    exited: {
        opacity: 0,
    },
};
var Transition = function (_a) {
    var children = _a.children, location = _a.location;
    var renderTransitionComponent = function (status) { return (_jsx("div", __assign({ style: __assign({}, getTransitionStyles[status]) }, { children: children }))); };
    if (location) {
        return (_jsx(TransitionGroup, { children: _jsx(ReactTransition, __assign({ timeout: {
                    enter: timeout,
                    exit: timeout,
                }, addEndListener: function (node, done) {
                    // You can customize the end listener logic here if needed
                    node.addEventListener("transitionend", done, false);
                } }, { children: function (status) { return renderTransitionComponent(status); } }), location.pathname) }));
    }
    else {
        return _jsx("div", { children: "Loading..." });
    }
};
export default Transition;
