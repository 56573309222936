var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { careers } from "@/Data";
import Seo from '@/Components/Seo';
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import { PageHeaderTwo } from "@/Components/PageHeader";
import ListingArea from '@/Components/Listing';
import ContactArea from '@/Components/ContactArea';
var Careers = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: "Careers" }), _jsx(Header, {}), _jsxs("main", __assign({ className: "site-wrapper-reveal" }, { children: [_jsx(PageHeaderTwo, { data: careers.pageHeader }), _jsx(ListingArea, { data: careers.sectionOne }), _jsx(ContactArea, {})] })), _jsx(Footer, {})] }));
};
export default Careers;
