var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "@/Components/UI/SectionTitle";
import SwiperSlider, { SwiperSlide } from "@/Components/UI/Swiper";
import { TestimonalOne } from "@/Components/Testimonal";
import { SectionWrap } from "./style";
// import author1img from "@data/images/avatar/avatar-01-70x70.jpg";
// import author2img from "@data/images/avatar/avatar-02-70x70.jpg";
// import author3img from "@data/images/avatar/avatar-03-70x70.jpg";
// import author4img from "@data/images/avatar/avatar-04-70x70.jpg";
var slider = {
    slidesPerView: 3,
    centeredSlides: true,
    loop: true,
    speed: 1000,
    spaceBetween: 50,
    pagination: true,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        991: {
            slidesPerView: 2,
        },
        1499: {
            slidesPerView: 3,
        },
    },
};
// const testimonials = [
//     {
//         id: 1,
//         image: { src: author1img },
//         name: "Abbie Ferguson",
//         designation: "Marketing",
//         subject:
//             "The Align Business Systems team works really hard to ensure high level of quality",
//         description:
//             "I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Align Business Systems is one of the most impressive to me.",
//         rating: 5,
//     },
//     {
//         id: 2,
//         image: { src: author2img },
//         name: "John Snow",
//         designation: "Web designer",
//         subject:
//             "The Align Business Systems team works really hard to ensure high level of quality",
//         description:
//             "I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Align Business Systems is one of the most impressive to me.",
//         rating: 3,
//     },
//     {
//         id: 3,
//         image: { src: author3img },
//         name: "Monica Blews",
//         designation: "Web developer",
//         subject:
//             "The Align Business Systems team works really hard to ensure high level of quality",
//         description:
//             "I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Align Business Systems is one of the most impressive to me.",
//         rating: 4,
//     },
//     {
//         id: 4,
//         image: { src: author4img },
//         name: "Stephen",
//         designation: "Web developer",
//         subject:
//             "The Align Business Systems team works really hard to ensure high level of quality",
//         description:
//             "I’ve been working with over 35 IT companies on more than 200 projects of our company, but @Align Business Systems is one of the most impressive to me.",
//         rating: 4,
//     },
// ];
var TestimonalsSectionOne = function (_a) {
    var data = _a.data;
    var title = data.title, subTitle = data.subTitle, items = data.items;
    return (_jsx(SectionWrap, { children: _jsxs(Container, __assign({ fluid: true, className: "cp-80" }, { children: [_jsx(Row, { children: _jsx(Col, __assign({ md: 12 }, { children: _jsx(SectionTitle, { mb: "60px", subtitle: subTitle, title: title }) })) }), _jsx(Row, { children: _jsx(Col, __assign({ lg: 12 }, { children: _jsx(SwiperSlider, __assign({ options: slider, paginationTop: "80px", opacityStyle: true }, { children: items === null || items === void 0 ? void 0 : items.map(function (item, idx) {
                                return (_jsx(SwiperSlide, __assign({ className: "item" }, { children: _jsx(TestimonalOne, { authorName: item.title, authorRole: item.subTitle, authorImg: item.image, review: item.text }) }), idx));
                            }) })) })) })] })) }));
};
export default TestimonalsSectionOne;
