var pageHeader = {
    title: "Events",
    path: "/events"
};
var galleries = [
    {
        galleryID: "itcn-asia-2k23",
        images: [
            {
                "src": "Events/ITCN/img-1.jpg",
                "width": 300,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-2.jpg",
                "width": 150,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-3.jpg",
                "width": 304,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-4.jpg",
                "width": 304,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-5.jpg",
                "width": 300,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-6.jpg",
                "width": 267,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-7.jpg",
                "width": 300,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-8.jpg",
                "width": 300,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-9.jpg",
                "width": 300,
                "height": 200
            },
            {
                "src": "Events/ITCN/img-10.jpg",
                "width": 134,
                "height": 200
            }
        ]
    }
];
export var events = {
    pageHeader: pageHeader,
    galleries: galleries
};
export default events;
