var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Image from "@/Components/UI/Image";
import { TeamMemberWrap, TeamMemberInner, TeamMemberImage, TeamMemberInfo, TeamMemberName, } from "./style";
var TeamMember = function (_a) {
    var image = _a.image, name = _a.name, designation = _a.designation, socials = _a.socials, layout = _a.layout;
    return (_jsx(TeamMemberWrap, __assign({ "$layout": layout }, { children: _jsxs(TeamMemberInner, { children: [_jsx(TeamMemberImage, __assign({ "$layout": layout }, { children: image && (_jsx(Image, { src: image, alt: name })) })), _jsxs(TeamMemberInfo, __assign({ "$layout": layout }, { children: [name && (_jsx(TeamMemberName, __assign({ "$layout": layout }, { children: name }))), designation && _jsx("p", { dangerouslySetInnerHTML: { __html: designation } })] }))] }) })));
};
TeamMember.defaultProps = {
    layout: 1,
    social: [],
};
export default TeamMember;
