var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect, useRef } from "react";
var useSticky = function () {
    var _a = __read(useState(0), 2), totalHeaderHeight = _a[0], setTotalHeaderHeight = _a[1];
    var _b = __read(useState(false), 2), sticky = _b[0], setSticky = _b[1];
    var headerRef = useRef(null);
    var fixedRef = useRef(null);
    useEffect(function () {
        if (headerRef.current)
            setTotalHeaderHeight(headerRef.current.clientHeight);
    }, [totalHeaderHeight]);
    useEffect(function () {
        var scrollHandler = function () {
            var scrollPos = window.scrollY;
            if (scrollPos > totalHeaderHeight) {
                setSticky(true);
            }
            else {
                setSticky(false);
            }
        };
        window.addEventListener("scroll", scrollHandler);
        return function () {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [sticky, totalHeaderHeight]);
    return { sticky: sticky, headerRef: headerRef, fixedRef: fixedRef };
};
export default useSticky;
