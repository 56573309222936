var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var BrandLogoWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: relative;\n    a {\n        display: block;\n        text-align: center;\n    }\n    .brand-logo__image {\n        transition: all 0.4s linear;\n    }\n    ", "\n\n    ", "\n\n    ", "\n    \n    ", "\n"], ["\n    position: relative;\n    a {\n        display: block;\n        text-align: center;\n    }\n    .brand-logo__image {\n        transition: all 0.4s linear;\n    }\n    ", "\n\n    ", "\n\n    ", "\n    \n    ", "\n"])), function (props) {
    return props.$layout === 1 && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            .brand-logo__image {\n                &-hover {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: scale(0.8, 0.8);\n                    transition: all 0.4s linear;\n                    position: absolute;\n                    top: 0;\n                    left: 0;\n                    width: 100%;\n                }\n            }\n            &:hover {\n                .brand-logo__image {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: scale(0.8, 0.8);\n                    &-hover {\n                        opacity: 1;\n                        visibility: visible;\n                        transform: none;\n                    }\n                }\n            }\n        "], ["\n            .brand-logo__image {\n                &-hover {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: scale(0.8, 0.8);\n                    transition: all 0.4s linear;\n                    position: absolute;\n                    top: 0;\n                    left: 0;\n                    width: 100%;\n                }\n            }\n            &:hover {\n                .brand-logo__image {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: scale(0.8, 0.8);\n                    &-hover {\n                        opacity: 1;\n                        visibility: visible;\n                        transform: none;\n                    }\n                }\n            }\n        "])));
}, function (props) {
    return props.$layout === 2 && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            .brand-logo__image {\n                &-hover {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: translate(-50%, 20px);\n                    transition: all 0.4s linear;\n                    position: absolute;\n                    top: 0;\n                    left: 50%;\n                    width: 100%;\n                }\n            }\n            &:hover {\n                .brand-logo__image {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: translateY(-20px);\n                    &-hover {\n                        visibility: visible;\n                        opacity: 1;\n                        transform: translate(-50%, 0);\n                    }\n                }\n            }\n        "], ["\n            .brand-logo__image {\n                &-hover {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: translate(-50%, 20px);\n                    transition: all 0.4s linear;\n                    position: absolute;\n                    top: 0;\n                    left: 50%;\n                    width: 100%;\n                }\n            }\n            &:hover {\n                .brand-logo__image {\n                    visibility: hidden;\n                    opacity: 0;\n                    transform: translateY(-20px);\n                    &-hover {\n                        visibility: visible;\n                        opacity: 1;\n                        transform: translate(-50%, 0);\n                    }\n                }\n            }\n        "])));
}, function (props) {
    return props.$layout === 3 && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            .brand-logo__image {\n                opacity: 0.4;\n                &:hover {\n                    opacity: 1;\n                }\n            }\n        "], ["\n            .brand-logo__image {\n                opacity: 0.4;\n                &:hover {\n                    opacity: 1;\n                }\n            }\n        "])));
}, function (props) {
    return props.$layout === 4 && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            .brand-logo__image {\n                opacity: 1;\n                &:hover {\n                    opacity: 0.4;\n                }\n            }\n        "], ["\n            .brand-logo__image {\n                opacity: 1;\n                &:hover {\n                    opacity: 0.4;\n                }\n            }\n        "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
