var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import styled, { css, device, typography, space, themeGet, layout, } from "@/Theme";
import { allowedTags } from "@/Utils";
export var StyledSectionTitle = styled("div").withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return !__spreadArray([], __read(allowedTags), false).includes(prop) && defaultValidatorFn(prop);
    },
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n    ", ";\n    ", "\n"], ["\n    ", ";\n    ", ";\n    ", "\n"])), typography, space, layout);
export var StyledTitle = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", "\n    span {\n        color: ", ";\n    }\n"], ["\n    ", "\n    span {\n        color: ", ";\n    }\n"])), function (_a) {
    var $layout = _a.$layout;
    return $layout === 2 && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            margin-block-end: 10px;\n            ", " {\n                margin-block-end: 20px;\n            }\n        "], ["\n            margin-block-end: 10px;\n            ", " {\n                margin-block-end: 20px;\n            }\n        "])), device.medium);
}, themeGet("colors.primary"));
export var StyledSubtitle = styled.h6(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: #575757;\n    font-weight: bold;\n    ", "\n    ", "\n"], ["\n    color: #575757;\n    font-weight: bold;\n    ", "\n    ", "\n"])), function (_a) {
    var $layout = _a.$layout;
    return $layout === 1 && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            margin-block-start: -5px;\n            margin-block-end: 10px;\n            text-transform: uppercase;\n            font-size: 14px;\n            letter-spacing: 3px;\n            line-height: 1.43;\n            ", " {\n                margin-block-end: 20px;\n            }\n        "], ["\n            margin-block-start: -5px;\n            margin-block-end: 10px;\n            text-transform: uppercase;\n            font-size: 14px;\n            letter-spacing: 3px;\n            line-height: 1.43;\n            ", " {\n                margin-block-end: 20px;\n            }\n        "])), device.medium);
}, function (_a) {
    var $layout = _a.$layout;
    return $layout === 2 && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            font-size: 18px;\n        "], ["\n            font-size: 18px;\n        "])));
});
export var StyledDesc = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-block-start: 20px;\n    color: #575757;\n    ", " {\n        margin-block-start: 30px;\n    }\n    ", "\n"], ["\n    margin-block-start: 20px;\n    color: #575757;\n    ", " {\n        margin-block-start: 30px;\n    }\n    ", "\n"])), device.medium, space);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
