var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { FormGroup, Input, Select, TelephoneInput } from "@/Components/UI/FormElements";
import { hasKey } from "@/Utils";
import Button from "@/Components/UI/Button";
import { Col, Row } from "../UI/Wrapper";
import countryList from 'react-select-country-list';
var DemoForm = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var formUrl = "https://getform.io/f/1022fe2a-f195-4de6-a394-a9cc1ccd6a22";
    var options = useMemo(function () { return countryList().getData(); }, []);
    var _h = useForm(), register = _h.register, handleSubmit = _h.handleSubmit, errors = _h.formState.errors;
    var _j = __read(useState({
        submitting: false,
        status: null,
    }), 2), serverState = _j[0], setServerState = _j[1];
    var handleServerResponse = function (ok, msg, form) {
        setServerState({
            submitting: false,
            status: { ok: ok, msg: msg },
        });
        if (ok) {
            form.reset();
        }
    };
    var onSubmit = function (data, e) {
        var form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: formUrl,
            data: data,
        })
            .then(function (r) {
            handleServerResponse(true, "Thanks! for contacting with us", form);
        })
            .catch(function (r) {
            handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (_jsxs("form", __assign({ className: "appointment-form", onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(Row, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsx(Input, __assign({ type: "text", id: "name", placeholder: "Name *", feedbackText: (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message, state: hasKey(errors, "name") ? "error" : "success", showState: !!hasKey(errors, "name") }, register("name", {
                                required: "Name is required",
                            }))) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsx(Input, __assign({ type: "email", id: "email", placeholder: "Email *", feedbackText: (_b = errors === null || errors === void 0 ? void 0 : errors.email) === null || _b === void 0 ? void 0 : _b.message, state: hasKey(errors, "email") ? "error" : "success", showState: !!hasKey(errors, "email") }, register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            }))) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsxs(Select, __assign({ id: "country", feedbackText: (_c = errors === null || errors === void 0 ? void 0 : errors.country) === null || _c === void 0 ? void 0 : _c.message, state: hasKey(errors, "country")
                                    ? "error"
                                    : "success", showState: !!hasKey(errors, "country"), hover: 2 }, register("country", {
                                required: "Please select a Country",
                            }), { children: [_jsx("option", __assign({ value: "" }, { children: "Select Country" })), options.map(function (option) { return (_jsx("option", __assign({ value: option.value }, { children: option.label }))); })] })) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsx(TelephoneInput, __assign({ id: "phone_number", placeholder: "Phone Number *", feedbackText: (_d = errors === null || errors === void 0 ? void 0 : errors.phone_number) === null || _d === void 0 ? void 0 : _d.message, state: hasKey(errors, "phone_number")
                                    ? "error"
                                    : "success", showState: !!hasKey(errors, "phone_number"), hover: 2 }, register("phone_number", {
                                required: "Please select a Phone Number",
                            }))) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsxs(Select, __assign({ id: "industry", feedbackText: (_e = errors === null || errors === void 0 ? void 0 : errors.industry) === null || _e === void 0 ? void 0 : _e.message, state: hasKey(errors, "industry")
                                    ? "error"
                                    : "success", showState: !!hasKey(errors, "industry"), hover: 2 }, register("industry", {
                                required: "Please select Industry",
                            }), { children: [_jsx("option", __assign({ value: "" }, { children: "Select Industry" })), _jsx("option", __assign({ value: "Manufacturing" }, { children: "Manufacturing" })), _jsx("option", __assign({ value: "Trading" }, { children: "Trading" })), _jsx("option", __assign({ value: "Services" }, { children: "Services" })), _jsx("option", __assign({ value: "Construction" }, { children: "Construction" })), _jsx("option", __assign({ value: "Real Estate" }, { children: "Real Estate" })), _jsx("option", __assign({ value: "Other" }, { children: "Other" }))] })) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsxs(Select, __assign({ id: "department", feedbackText: (_f = errors === null || errors === void 0 ? void 0 : errors.department) === null || _f === void 0 ? void 0 : _f.message, state: hasKey(errors, "department")
                                    ? "error"
                                    : "success", showState: !!hasKey(errors, "department"), hover: 2 }, register("department", {
                                required: "Please select Department",
                            }), { children: [_jsx("option", __assign({ value: "" }, { children: "Select Department" })), _jsx("option", __assign({ value: "Information Technology (IT)" }, { children: "Information Technology (IT)" })), _jsx("option", __assign({ value: "Finance" }, { children: "Finance" })), _jsx("option", __assign({ value: "Operations" }, { children: "Operations" })), _jsx("option", __assign({ value: "Procurement" }, { children: "Procurement" })), _jsx("option", __assign({ value: "Other" }, { children: "Other" }))] })) })) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsx(Input, __assign({ type: "text", id: "company", placeholder: "Company *", feedbackText: (_g = errors === null || errors === void 0 ? void 0 : errors.company) === null || _g === void 0 ? void 0 : _g.message, state: hasKey(errors, "company") ? "error" : "success", showState: !!hasKey(errors, "company") }, register("company", {
                                required: "Company is required",
                            }))) })) }))] }), _jsx(Button, __assign({ type: "submit", disabled: serverState.submitting }, { children: "Send Message" })), serverState.status && (_jsx("p", __assign({ className: "form-output ".concat(!serverState.status.ok ? "errorMsg" : "success") }, { children: serverState.status.msg })))] })));
};
export default DemoForm;
