var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { events } from "@/Data";
import Seo from '@/Components/Seo';
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import { PageHeaderTwo } from "@/Components/PageHeader";
import ContactArea from '@/Components/ContactArea';
// @ts-ignore
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import Image from '@/Components/UI/Image';
import { PswpGallery } from './styles';
import SectionTitle from '@/Components/UI/SectionTitle';
import { Col, Container, Row } from '@/Components/UI/Wrapper';
var Events = function () {
    useEffect(function () {
        var lightbox = new PhotoSwipeLightbox({
            gallery: '#' + events.galleries[0].galleryID,
            children: 'a',
            pswpModule: function () { return import('photoswipe'); },
        });
        lightbox.init();
        return function () {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: "Events" }), _jsx(Header, {}), _jsxs("main", __assign({ className: "site-wrapper-reveal" }, { children: [_jsx(PageHeaderTwo, { data: events.pageHeader }), _jsx(Container, { children: _jsx(Row, __assign({ alignItems: "center" }, { children: _jsxs(Col, __assign({ md: 12 }, { children: [_jsx(SectionTitle, { className: "my-4", subtitle: "<span>ITCN</span> Asia 2023", text: "Align Business Systems is thrilled to have been a part of ITCN Asia 2023, where we unveiled our state-of-the-art ERP solutions. We want to express our sincere appreciation to all those who stopped by our booth and joined us for meaningful conversations throughout the event. Keep an eye out for updates on our upcoming projects and opportunities to connect and collaborate. Your support means the world to us" }), _jsx(PswpGallery, __assign({ id: events.galleries[0].galleryID }, { children: events.galleries[0].images.map(function (image, index) { return (_jsx("a", __assign({ href: require("@/Assets/Images/".concat(image.src)).default, "data-pswp-width": image.width, "data-pswp-height": image.height, target: "_blank", rel: "noreferrer" }, { children: _jsx(Image, { src: image.src, styles: { height: image.height } }) }), events.galleries[0].galleryID + '-' + index)); }) }))] })) })) }), _jsx(ContactArea, {})] })), _jsx(Footer, {})] }));
};
export default Events;
