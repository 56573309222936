var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Children, isValidElement, cloneElement } from "react";
import { SocialLink } from "./social-link";
import { SocialWrap } from "./style";
var Social = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var childProps = {
        color: props.color,
        size: props.size,
        shape: props.shape,
        variant: props.variant,
        hover: props.hover,
        space: props.space,
        tooltip: props.tooltip,
        tooltip_bg: props.tooltip_bg,
        tooltip_position: props.tooltip_position,
    };
    var childrenWithProps = Children.map(children, function (child) {
        if (isValidElement(child)) {
            return cloneElement(child, __assign({}, childProps));
        }
        return child;
    });
    return _jsx(SocialWrap, __assign({}, props, { children: childrenWithProps }));
};
Social.defaultProps = {
    space: "15px",
    size: "medium",
};
export { SocialLink };
export default Social;
