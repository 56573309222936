var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BoxIconWrap, BoxIconInner, BoxIconImg, BoxIconContent, BoxIcontitle, BoxIconText, } from "./style";
import Image from "@/Components/UI/Image";
var BoxIconTwo = function (_a) {
    var icon = _a.icon, title = _a.title, desc = _a.desc, path = _a.path, className = _a.className, restProps = __rest(_a, ["icon", "title", "desc", "path", "className"]);
    return (_jsx(BoxIconWrap, { children: _jsxs(BoxIconInner, __assign({ className: className }, restProps, { children: [icon && (_jsx(BoxIconImg, { children: _jsx(Image, { src: icon }) })), _jsxs(BoxIconContent, { children: [title && _jsx(BoxIcontitle, { children: title }), desc && _jsx(BoxIconText, { children: desc })] })] })) }));
};
BoxIconTwo.defaultProps = {
    path: "/",
};
export default BoxIconTwo;
