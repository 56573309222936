var pageHeader = {
    title: "About Us",
    path: "/about-us"
};
var sectionOne = {
    title: "Benefits of BusinessFlow",
    subTitle: "Unlock the Power of <span>Cloud-Based ERP</span> for Your Business",
    items: [
        {
            title: "Enhanced Efficiency and Productivity",
            text: "Businessflo boosts efficiency and productivity by automating manual tasks, streamlining processes, and providing real-time visibility into your operations. With streamlined workflows and centralized data management, your team can focus on core business activities and achieve more in less time.",
            icon: "Icons/productivity.png"
        },
        {
            title: "Seamless Collaboration and Communication",
            text: "Businessflo facilitates seamless collaboration and communication across departments and teams. With features like centralized document sharing, task management, and integrated communication tools, you can improve cross-functional collaboration, enhance decision-making, and foster a culture of teamwork.",
            icon: "Icons/communication.png"
        },
        {
            title: "Scalable and Agile Solution",
            text: "Businessflo is designed to adapt to your business needs and grow with your organization. As your business expands, the ERP system offers scalability, allowing you to add new users, modules, and functionalities seamlessly. This flexibility ensures that your ERP solution aligns with your evolving business requirements.",
            icon: "Icons/agile.png"
        },
        {
            title: "Comprehensive Data Analysis and Insights",
            text: "Businessflo empowers you to make data-driven decisions by providing comprehensive data analysis and insights. With customizable reports, interactive dashboards, and advanced analytics capabilities, you can gain valuable insights into your business performance, identify trends, and uncover opportunities for improvement.",
            icon: "Icons/dashboard.png"
        },
        {
            title: "Enhanced Customer Experience",
            text: "Businessflo helps you deliver exceptional customer experiences by enabling better order management, efficient customer support, and streamlined sales processes. With a 360-degree view of customer information, you can personalize interactions, improve response times, and build strong customer relationships.",
            icon: "Icons/customer-satisfaction.png"
        },
        {
            title: "Well-Written Documentations",
            text: "Businessflo comes with well-written documentations that provide comprehensive guidance on system usage, configurations, and processes. These detailed resources enable your teBusinessFlow helps you deliver exceptional customer experiences by enabling better order management, efficient customer support, and streamlined sales processes.",
            icon: "Icons/documentation.png"
        },
    ]
};
export var about = {
    pageHeader: pageHeader,
    sectionOne: sectionOne
};
export default about;
