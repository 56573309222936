var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SocialLinkWrap } from "./style";
export var SocialLink = function (_a) {
    var path = _a.path, children = _a.children, title = _a.title, tooltip = _a.tooltip, tooltip_bg = _a.tooltip_bg, tooltip_position = _a.tooltip_position, color = _a.color, size = _a.size, shape = _a.shape, variant = _a.variant, space = _a.space, hover = _a.hover, props = __rest(_a, ["path", "children", "title", "tooltip", "tooltip_bg", "tooltip_position", "color", "size", "shape", "variant", "space", "hover"]);
    return (_jsxs(SocialLinkWrap, __assign({}, props, { href: path, target: "_blank", rel: "noopener noreferrer", "$tooltip": tooltip, "$tooltip_bg": tooltip_bg, "$tooltip_position": tooltip_position, "$hastitle": title && !tooltip, "$color": color, "$size": size, "$shape": shape, "$variant": variant, "$space": space, "$hover": hover }, { children: [children, title && _jsx("span", __assign({ className: "title" }, { children: title }))] })));
};
