var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Image from "@/Components/UI/Image";
import { TestimonialWrap, TestimonialSubject, TestimonialReview, AuthorInfoWrap, AuthorMedia, AuthorInfo, AuthorName, AuthorRole, } from "./style";
var Testimonial = function (_a) {
    var authorImg = _a.authorImg, authorName = _a.authorName, authorRole = _a.authorRole, subject = _a.subject, review = _a.review, rest = __rest(_a, ["authorImg", "authorName", "authorRole", "subject", "review"]);
    return (_jsxs(TestimonialWrap, __assign({}, rest, { children: [_jsx(TestimonialSubject, { children: "Reviews" }), review && _jsx(TestimonialReview, { children: review }), _jsxs(AuthorInfoWrap, { children: [authorImg && (_jsx(AuthorMedia, { children: _jsx(Image, { src: authorImg, alt: authorName }) })), (authorName || authorRole) && (_jsxs(AuthorInfo, { children: [authorName && _jsx(AuthorName, { children: authorName }), authorRole && _jsx(AuthorRole, { children: authorRole })] }))] })] })));
};
export default Testimonial;
