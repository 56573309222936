var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, device, themeGet } from "@/Theme";
export var SectionWrap = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-block-start: 53px;\n    padding-block-end: 53px;\n    ", " {\n        padding-block-start: 72px;\n        padding-block-end: 72px;\n    }\n    ", " {\n        padding-block-start: 89px;\n        padding-block-end: 89px;\n    }\n"], ["\n    padding-block-start: 53px;\n    padding-block-end: 53px;\n    ", " {\n        padding-block-start: 72px;\n        padding-block-end: 72px;\n    }\n    ", " {\n        padding-block-start: 89px;\n        padding-block-end: 89px;\n    }\n"])), device.medium, device.large);
export var JobList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var JobItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border-radius: ", ";\n\n    padding: 20px 30px 20px;\n    ", " {\n        padding: 33px 50px 36px;\n    }\n    ", "\n"], ["\n    border-radius: ", ";\n\n    padding: 20px 30px 20px;\n    ", " {\n        padding: 33px 50px 36px;\n    }\n    ", "\n"])), themeGet("radii.md"), device.small, function (props) {
    return !props.isEven && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            background: #f6f2ed;\n        "], ["\n            background: #f6f2ed;\n        "])));
});
export var JobTitle = styled.h5(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var JobType = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 16px;\n    margin-block-end: 10px;\n    display: block;\n    ", " {\n        margin-block-start: 12px;\n        font-size: 18px;\n    }\n"], ["\n    font-size: 16px;\n    margin-block-end: 10px;\n    display: block;\n    ", " {\n        margin-block-start: 12px;\n        font-size: 18px;\n    }\n"])), device.medium);
export var JobDesc = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var JobButton = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    text-align: start;\n    margin-block-start: 20px;\n    ", " {\n        text-align: center;\n    }\n    .mt-btn {\n        border-color: ", ";\n    }\n"], ["\n    text-align: start;\n    margin-block-start: 20px;\n    ", " {\n        text-align: center;\n    }\n    .mt-btn {\n        border-color: ", ";\n    }\n"])), device.large, themeGet("colors.borderDash"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
