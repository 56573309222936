var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { space, themeGet } from "@/Theme";
export var BoxIconInner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-block-start: 36px;\n    padding-block-end: 26px;\n    padding-inline: 15px;\n    border-radius: ", ";\n    background: #fff;\n    border: 2px solid ", ";\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    text-align: center;\n    height: 100%;\n    ", ";\n"], ["\n    padding-block-start: 36px;\n    padding-block-end: 26px;\n    padding-inline: 15px;\n    border-radius: ", ";\n    background: #fff;\n    border: 2px solid ", ";\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    text-align: center;\n    height: 100%;\n    ", ";\n"])), themeGet("radii.md"), themeGet("colors.gray.400"), space);
export var BoxIconImg = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: 65px;\n    text-align: center;\n    width: 65px;\n    margin-inline: auto;\n    margin-block-end: 29px;\n    color: ", ";\n    svg * {\n        stroke: ", ";\n    }\n    i {\n        color: ", ";\n    }\n"], ["\n    height: 65px;\n    text-align: center;\n    width: 65px;\n    margin-inline: auto;\n    margin-block-end: 29px;\n    color: ", ";\n    svg * {\n        stroke: ", ";\n    }\n    i {\n        color: ", ";\n    }\n"])), themeGet("colors.primary"), themeGet("colors.primary"), themeGet("colors.primary"));
export var BoxIconContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    text-align: center;\n"], ["\n    text-align: center;\n"])));
export var BoxIcontitle = styled.h5(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: 500;\n    line-height: 1.25;\n    margin-block-end: 12px;\n    color: ", ";\n"], ["\n    font-weight: 500;\n    line-height: 1.25;\n    margin-block-end: 12px;\n    color: ", ";\n"])), themeGet("colors.primary"));
export var BoxIconText = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-block-end: 0;\n"], ["\n    margin-block-end: 0;\n"])));
export var BoxIconBtn = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-block-start: 20px;\n"], ["\n    margin-block-start: 20px;\n"])));
export var BoxIconWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    height: 100%;\n\n    &:hover {\n        ", " {\n            border-color: #fff;\n            transform: translateY(-5px);\n            background: #fff;\n            box-shadow: ", ";\n        }\n    }\n"], ["\n    height: 100%;\n\n    &:hover {\n        ", " {\n            border-color: #fff;\n            transform: translateY(-5px);\n            background: #fff;\n            box-shadow: ", ";\n        }\n    }\n"])), BoxIconInner, themeGet("shadows.default"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
