var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import SectionTitle from "@/Components/UI/SectionTitle";
import { BoxIconTwo } from "@/Components/BoxIcon";
import { SectionWrap } from "./style";
var BoxIconSectionTwo = function (_a) {
    var data = _a.data, className = _a.className;
    var title = data.title, subTitle = data.subTitle, items = data.items;
    return (_jsx(SectionWrap, __assign({ className: className }, { children: _jsxs(Container, { children: [_jsx(Row, { children: _jsx(Col, __assign({ lg: 12 }, { children: _jsx(SectionTitle, { mb: "40px", title: title, subtitle: subTitle }) })) }), _jsx(Row, { children: items === null || items === void 0 ? void 0 : items.map(function (item, i) { return (_jsx(Col, __assign({ lg: 4, md: 6, mt: "30px", className: "align-items-stretch" }, { children: _jsx(BoxIconTwo, { title: item.title, desc: item.text, icon: item.icon }) }), "box-icon-2nd-".concat(i))); }) })] }) })));
};
export default BoxIconSectionTwo;
