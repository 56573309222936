// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Google fonts Inter */

@font-face {
    font-family: "Font Awesome 5 Brands";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-brands-400.ttf);
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-regular-400.ttf);
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-solid-900.ttf);
}
`, "",{"version":3,"sources":["webpack://./app/Assets/Css/fonts.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB,uBAAuB;;AAGvB;IACI,oCAAoC;IACpC,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,8FAA8F;AAClG;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,+FAA+F;AACnG;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,6FAA6F;AACjG","sourcesContent":["@charset \"UTF-8\";\r\n/* Google fonts Inter */\r\n@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap\");\r\n\r\n@font-face {\r\n    font-family: \"Font Awesome 5 Brands\";\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-display: block;\r\n    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-brands-400.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Font Awesome 5 Free\";\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-display: block;\r\n    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-regular-400.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Font Awesome 5 Free\";\r\n    font-style: normal;\r\n    font-weight: 900;\r\n    font-display: block;\r\n    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/webfonts/fa-solid-900.ttf);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
