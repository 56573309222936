var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import Text from "@/Components/UI/Text";
import Button from "@/Components/UI/Button";
import Image from "@/Components/UI/Image";
import { HeroWrapper, HeroTextBox, ImageBoxWrap, ImageBoxOne, StyledTitle, StyledSubtitle, } from "./style";
import React, { useState } from "react";
import Modal from "@/Components/Modal";
import QuoteForm from "@/Components/QuoteForm";
import DemoForm from "@/Components/DemoForm";
var HeroAreaOne = function () {
    var _a = __read(useState(false), 2), quoteModalState = _a[0], toggleQuoteModal = _a[1];
    var _b = __read(useState(false), 2), demoModalState = _b[0], toggleDemoModal = _b[1];
    var openQuoteModal = function () {
        toggleQuoteModal(true);
    };
    var openDemoModal = function () {
        toggleDemoModal(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(React.Suspense, __assign({ fallback: _jsx("div", {}) }, { children: _jsx(Modal, __assign({ header: "Get Quote", isOpen: quoteModalState, onClose: function () { return toggleQuoteModal(false); } }, { children: _jsx(QuoteForm, {}) })) })), _jsx(React.Suspense, __assign({ fallback: _jsx("div", {}) }, { children: _jsx(Modal, __assign({ header: "Request Demo", isOpen: demoModalState, onClose: function () { return toggleDemoModal(false); } }, { children: _jsx(DemoForm, {}) })) })), _jsx(HeroWrapper, { children: _jsx(Container, __assign({ fluid: true }, { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsxs(HeroTextBox, { children: [_jsx(StyledSubtitle, __assign({ as: "h6" }, { children: "Simplify Your Business Process." })), _jsxs(StyledTitle, __assign({ as: "h1" }, { children: ["Transform Your ", _jsx("span", { children: "Business." })] })), _jsx(Text, __assign({ style: { color: '#575757', fontWeight: 'bold' } }, { children: "Experience the ultimate synergy between technology and business success." })), _jsx(Button, __assign({ mr: "8px", mt: "15px", onClick: openQuoteModal }, { children: "Get Quote" })), _jsx(Button, __assign({ mr: "8px", mt: "15px", onClick: openDemoModal, color: "light", variant: 'outlined' }, { children: "Request Demo" }))] }) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(ImageBoxWrap, { children: _jsx(ImageBoxOne, { children: _jsx(Image, { src: "Hero/hero-04.png", alt: "Home" }) }) }) }))] })) })) })] }));
};
export default HeroAreaOne;
