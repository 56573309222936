var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { typography, space } from "@/Theme";
export var StyleFormGroup = styled("div").withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return ![
            "p",
            "pl",
            "pr",
            "pt",
            "pb",
            "px",
            "m",
            "ml",
            "mr",
            "mt",
            "mb",
            "mx",
            "fontSize",
            "textAlign",
        ].includes(prop) && defaultValidatorFn(prop);
    },
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n    ", ";\n"], ["\n    ", ";\n    ", ";\n"])), typography, space);
var templateObject_1;
