var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
var useScrollTop = function () {
    var _a = __read(useState(false), 2), stick = _a[0], setStick = _a[1];
    useEffect(function () {
        var position = window.pageYOffset;
        var active = true;
        var scrollHandler = function () {
            var scrollPos = window.pageYOffset;
            if (scrollPos < 200) {
                setStick(false);
            }
            else if (scrollPos < position) {
                setStick(true);
            }
            else {
                setStick(false);
            }
            position = scrollPos;
        };
        if (active) {
            window.addEventListener("scroll", scrollHandler);
        }
        return function () {
            active = false;
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [stick]);
    return stick;
};
export default useScrollTop;
