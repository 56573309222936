var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { LogoWrapper } from "./style";
var Logo = function (_a) {
    var className = _a.className, variant = _a.variant, restProps = __rest(_a, ["className", "variant"]);
    return (_jsx(LogoWrapper, __assign({ className: className, "$variant": variant }, restProps, { children: _jsxs(Link, __assign({ to: "/" }, { children: [_jsx("img", { src: require("../../Assets/Images/Logo/white-logo.png").default, className: "img-fluid static-img light-logo", alt: "logo", width: 312.5, height: 24 }), _jsx("img", { src: require("../../Assets/Images/Logo/dark-logo.png").default, className: "img-fluid static-img dark-logo", alt: "logo", width: 312.5, height: 24 })] })) })));
};
Logo.defaultProps = {
    variant: "dark",
};
export default Logo;
