var clients = [
    {
        title: "Danpak Food Industries (Pvt.) Ltd.",
        image: "Clients/danpak.png",
        hoverImage: "Clients/danpak-hover.png",
        text: "Danpak Food Industries (Pvt) Limited, a leading confectionery company established in 1984 was a collaborative venture with a Danish company. Within few years it gained popularity nationwide.\n        <br /><br />\n        Danpak offers wide range of chocolates, bubble gums, candies, toffees, cakes and biscuits etc. to its customer along with the associated brands Taste Factory, Forno, Spencer, and Merry which are accepted locally and globally."
    },
    {
        title: "Sitech Pharmaceuticals (Pvt.) Ltd.",
        image: "Clients/sitech.png",
        hoverImage: "Clients/sitech-hover.png",
        text: "SiTech Pharmaceuticals is in the business of sales and marketing of imported and locally produced medicines and medical solutions. SiTech head office is in the port city of Karachi, Pakistan.\n        <br /><br />\n        SiTech Pharma consists of experienced professionals providing quality medicines and life saving solutions in Pakistan through professionally trained marketing team and a nationwide network of distributors."
    },
    {
        title: "ITT Foods (Pvt.) Ltd.",
        image: "Clients/dipitt.png",
        hoverImage: "Clients/dipitt-hover.png",
        text: "Dipitt is a brand that is all about you. It is inspired by flavors from all over the world, crafted specifically with your taste buds in mind. We have created the perfect sauce for every occasion, whether you prefer a mild or wild flavor experience. With Dipitt, you will embark on a culinary adventure, flying over countries and diving under seas, all through the deliciousness of our sauces."
    },
    {
        title: "Kings Group",
        image: "Clients/kings.png",
        hoverImage: "Clients/kings-hover.png",
        text: "Kings Group is a traditional tightly knit firm of housebuilders, developers, and contractors established in 1977. The company was established to function as both a developer and a contractor which allows the flexibility to work in various capacities on a range of projects. Our passion is building and delivering attractive homes to pre-eminence and as a result, have built a reliable team to ensure the best for You. We have strong relationships with our valued customers whom we promise to always provide the level of quality that we impress upon. Kings Group prides itself on construction knowledge and capability to successfully deliver projects on time and budget. Our in-house team has a vast amount of experience and knowledge which gives us the ability to undertake the role of principal builders on a variety of projects."
    },
    {
        title: "VSolar (Pvt.) Ltd.",
        image: "Clients/vsolar.png",
        hoverImage: "Clients/vsolar-hover.png",
        text: "VSolar is Pakistan's leading fully integrated solar company offering services across the complete value chain."
    },
    {
        title: "Allied Axiom (Pvt.) Ltd.",
        image: "Clients/allied-axiom.png",
        hoverImage: "Clients/allied-axiom-hover.png",
        text: "Allied Axiom Chemical is not just the name of a Flavor House. It is the name of Trusted and Reliable source of Flavoring and ingredient needs. Allied Axiom Chemical formerly Allied Chemical Corporation was founded in 1972 in Karachi. Pakistan by Mr Sheikh Muhammad Ebrahim Dawawala (late) a chemical tycoon who spent more than half a century in manufacturing and trading cosmetics and chemicals."
    },
    {
        title: "Zamanat Pakistan",
        image: "Clients/zamanat.png",
        hoverImage: "Clients/zamanat-hover.png",
        text: "Zamanat is the one-stop platform that provides property solutions with ease. With multiple projects on board, Zamanat provides trusted property solutions to Pakistanis."
    },
    {
        title: "Tech Exons (Pvt.) Ltd.",
        image: "Clients/techexeons.png",
        hoverImage: "Clients/techexeons-hover.png",
        text: "Tech Exons Pvt. Ltd is a Human Risk Management company specializing in providing background verification services to B2B and B2C customers through our products and services."
    },
    {
        title: "Premier Mercantile Services (Pvt.) Ltd.",
        image: "Clients/pms.png",
        hoverImage: "Clients/pms-hover.png",
        text: "Premier Mercantile Services (Pvt.) Ltd. (PMS) - The pioneer company of the Group was founded in 1964. Through progressive investment, innovative management policies ad intimate knowledge of marine business, PMS captured a leading share of the market. It is now one of the largest terminal operatiors at Karachi Port."
    },
    {
        title: "Noon Academy",
        image: "Clients/noon.png",
        hoverImage: "Clients/noon-hover.png",
        text: "Noon Academy is an online learning platform that offers educational resources and tools to students of all ages. It provides a wide range of courses and study materials across various subjects, including math, science, languages, humanities, and more. Noon Academy aims to make quality education accessible to everyone, providing interactive lessons, practice exercises, and personalized learning experiences."
    },
    {
        title: "Al Umeed Rehabilitation Association (AURA)",
        image: "Clients/aura.png",
        hoverImage: "Clients/aura-hover.png",
        text: "Alumeed Rehabilitation Association (AURA) for Cerebral Palsy, a non- profit charitable organization, is first of its kind in Pakistan that offers specialized interdisciplinary Treatment, Rehabilitation, Special & Inclusive Education services for children and young adults suffering from Cerebral Palsy."
    },
];
export default clients;
