var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { themeGet, device } from "@/Theme";
import { animateUpDown } from "@/Assets/Css/animations";
// console.log("animateUpDown", animateUpDown);
export var HeroWrapper = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #fff;\n    margin-bottom: 20px;\n"], ["\n    background-color: #fff;\n    margin-bottom: 20px;\n"])));
export var HeroTextBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    max-width: 605px;\n    width: 100%;\n    padding-inline-start: 0;\n    margin-block-start: 50px;\n    margin-block-end: 20px;\n    margin-inline: auto;\n    text-align: center;\n\n    ", " {\n        padding-inline-start: 30px;\n        margin-inline-start: auto;\n        margin-inline-end: 0;\n        margin-block-start: 0;\n        margin-block-end: 0;\n        text-align: left;\n    }\n"], ["\n    max-width: 605px;\n    width: 100%;\n    padding-inline-start: 0;\n    margin-block-start: 50px;\n    margin-block-end: 20px;\n    margin-inline: auto;\n    text-align: center;\n\n    ", " {\n        padding-inline-start: 30px;\n        margin-inline-start: auto;\n        margin-inline-end: 0;\n        margin-block-start: 0;\n        margin-block-end: 0;\n        text-align: left;\n    }\n"])), device.medium);
export var ImageBoxWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    margin-inline: auto;\n    max-width: 370px;\n    ", " {\n        padding-block-start: 160px;\n        max-width: 100%;\n        margin-inline: 0;\n    }\n    ", " {\n        margin-block-start: 20px;\n        padding-block-start: 0;\n    }\n"], ["\n    position: relative;\n    margin-inline: auto;\n    max-width: 370px;\n    ", " {\n        padding-block-start: 160px;\n        max-width: 100%;\n        margin-inline: 0;\n    }\n    ", " {\n        margin-block-start: 20px;\n        padding-block-start: 0;\n    }\n"])), device.medium, device.large);
export var ImageBoxOne = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: relative;\n    z-index: 1;\n    margin-right: 0px;\n\n    animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);\n    animation-name: ", ";\n    animation-iteration-count: infinite;\n\n    img {\n        width: 100%;\n    }\n"], ["\n    position: relative;\n    z-index: 1;\n    margin-right: 0px;\n\n    animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);\n    animation-name: ", ";\n    animation-iteration-count: infinite;\n\n    img {\n        width: 100%;\n    }\n"])), animateUpDown);
export var StyledSubtitle = styled.h6(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-weight: bold;\n    color: #575757;\n    margin-block-end: 20px;\n"], ["\n    font-weight: bold;\n    color: #575757;\n    margin-block-end: 20px;\n"])));
export var StyledTitle = styled.h1(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-weight: 500;\n    margin-block-end: 15px;\n    color: ", ";\n    line-height: 40px;\n    font-weight: 700;\n    font-size: 40px;\n    span {\n        display: block;\n        line-height: 60px;\n        font-weight: 700;\n        font-size: 60px;\n        ", " {\n            line-height: 70px;\n            font-size: 70px;\n        }\n        ", " {\n            line-height: 130px;\n            font-size: 120px;\n        }\n    }\n"], ["\n    font-weight: 500;\n    margin-block-end: 15px;\n    color: ", ";\n    line-height: 40px;\n    font-weight: 700;\n    font-size: 40px;\n    span {\n        display: block;\n        line-height: 60px;\n        font-weight: 700;\n        font-size: 60px;\n        ", " {\n            line-height: 70px;\n            font-size: 70px;\n        }\n        ", " {\n            line-height: 130px;\n            font-size: 120px;\n        }\n    }\n"])), themeGet("colors.primary"), device.medium, device.large);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
