var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import styled, { space, typography } from "@/Theme";
import { Col } from "react-bootstrap";
export var StyledCol = styled(function (_a) {
    var p = _a.p, px = _a.px, py = _a.py, pt = _a.pt, pb = _a.pb, pl = _a.pl, pr = _a.pr, m = _a.m, mx = _a.mx, my = _a.my, mt = _a.mt, mb = _a.mb, ml = _a.ml, mr = _a.mr, textAlign = _a.textAlign, width = _a.width, height = _a.height, display = _a.display, justifyContent = _a.justifyContent, alignItems = _a.alignItems, rest = __rest(_a, ["p", "px", "py", "pt", "pb", "pl", "pr", "m", "mx", "my", "mt", "mb", "ml", "mr", "textAlign", "width", "height", "display", "justifyContent", "alignItems"]);
    return _jsx(Col, __assign({}, rest));
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n    ", ";\n"], ["\n    ", ";\n    ", ";\n"])), space, typography);
var templateObject_1;
