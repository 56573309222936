var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Seo from '@/Components/Seo';
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import { HeroAreaOne } from "@/Components/Hero";
import { ClientsAreaOne } from "@/Components/ClientsArea";
import { AboutAreaOne } from "@/Components/About";
import FaqArea from '@/Components/FAQ';
import ContactArea from '@/Components/ContactArea';
var Home = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: "Home" }), _jsx(Header, {}), _jsxs("main", __assign({ className: "site-wrapper-reveal" }, { children: [_jsx(HeroAreaOne, {}), _jsx(ClientsAreaOne, {}), _jsx(AboutAreaOne, {}), _jsx("hr", {}), _jsx(FaqArea, { length: 4 }), _jsx(ContactArea, {})] })), _jsx(Footer, {})] }));
};
export default Home;
