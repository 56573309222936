var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BsModal from "react-bootstrap/Modal";
import CloseButton from "@/Components/UI/CloseButton";
var Modal = function (_a) {
    var onClose = _a.onClose, isOpen = _a.isOpen, children = _a.children, header = _a.header;
    return (_jsxs(BsModal, __assign({ show: isOpen, onHide: onClose, centered: true, size: "lg" }, { children: [_jsx(CloseButton, { onClick: onClose, position: "absolute", right: "-40px", top: "-40px", color: "#fff" }), header ? (_jsx(BsModal.Header, __assign({ style: { fontWeight: 'bold' } }, { children: header }))) : null, _jsx(BsModal.Body, { children: children })] })));
};
export default Modal;
