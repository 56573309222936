var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { keyframes } from "styled-components";
export var fadeInDown = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    0% {\n        opacity: 0;\n        transform: translate3d(0, -100%, 0)\n    }\n    to {\n        opacity: 1;\n        transform: translateZ(0)\n    }\n"], ["\n    0% {\n        opacity: 0;\n        transform: translate3d(0, -100%, 0)\n    }\n    to {\n        opacity: 1;\n        transform: translateZ(0)\n    }\n"])));
export var boxImageAnimationSignal = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    0% {\n        opacity: 0;\n        transform: scale(0);\n    }\n    1% {\n        opacity: .14;\n    }\n    20% {\n        opacity: .3;\n    }\n    40% {\n        opacity: .4;\n    }\n    60% {\n        transform: scale(1);\n        opacity: 0;\n    }\n"], ["\n    0% {\n        opacity: 0;\n        transform: scale(0);\n    }\n    1% {\n        opacity: .14;\n    }\n    20% {\n        opacity: .3;\n    }\n    40% {\n        opacity: .4;\n    }\n    60% {\n        transform: scale(1);\n        opacity: 0;\n    }\n"])));
export var animateUpDown = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    0% {\n        transform: translateY(0px);\n    }\n    50% {\n        transform: translateY(-25px);\n    }\n    100% {\n        transform: translateY(0px);\n    }\n"], ["\n    0% {\n        transform: translateY(0px);\n    }\n    50% {\n        transform: translateY(-25px);\n    }\n    100% {\n        transform: translateY(0px);\n    }\n"])));
export var zoomBig = keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    0% {\n        transform: translate(-50%, -50%) scale(0);\n        opacity: 1;\n        border-width: 3px;\n    }\n    40% {\n        opacity: .5;\n        border-width: 2px;\n    }\n    65% {\n        border-width: 1px;\n    }\n    100% {\n        transform: translate(-50%, -50%) scale(1);\n        opacity: 0;\n        border-width: 1px;\n    }\n"], ["\n    0% {\n        transform: translate(-50%, -50%) scale(0);\n        opacity: 1;\n        border-width: 3px;\n    }\n    40% {\n        opacity: .5;\n        border-width: 2px;\n    }\n    65% {\n        border-width: 1px;\n    }\n    100% {\n        transform: translate(-50%, -50%) scale(1);\n        opacity: 0;\n        border-width: 1px;\n    }\n"])));
export var gradationMask = keyframes(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    0% {\n        transform: translate(-50%, -50%) scale(0);\n        opacity: 1;\n    }\n    90% {\n        opacity: 1;\n    }\n    100% {\n        transform: translate(-50%, -50%) scale(1);\n        opacity: 0;\n        border-color: transparent;\n    }\n"], ["\n    0% {\n        transform: translate(-50%, -50%) scale(0);\n        opacity: 1;\n    }\n    90% {\n        opacity: 1;\n    }\n    100% {\n        transform: translate(-50%, -50%) scale(1);\n        opacity: 0;\n        border-color: transparent;\n    }\n"])));
export var worldRotate = keyframes(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    0% {\n        transform: rotate(0deg) translateX(0px) rotate(0deg);\n    }\n    100% {\n        transform: rotate(0deg) translateY(0px) rotate(-360deg);\n    }\n"], ["\n    0% {\n        transform: rotate(0deg) translateX(0px) rotate(0deg);\n    }\n    100% {\n        transform: rotate(0deg) translateY(0px) rotate(-360deg);\n    }\n"])));
export var separatorBounce = keyframes(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    0%,\n    80%,\n    100% {\n      transform: scale(0.5);\n    }\n    40% {\n      transform: scale(1);\n    }\n"], ["\n    0%,\n    80%,\n    100% {\n      transform: scale(0.5);\n    }\n    40% {\n      transform: scale(1);\n    }\n"])));
export var jump = keyframes(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    0% {\n        transform: translateY(-10px) scale(0.9, 0.9);\n    }\n    100% {\n        transform: translateY(10px) scale(1, 1);\n    }\n"], ["\n    0% {\n        transform: translateY(-10px) scale(0.9, 0.9);\n    }\n    100% {\n        transform: translateY(10px) scale(1, 1);\n    }\n"])));
export var modalVideo = keyframes(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    from {\n        opacity: 0\n    }\n    to {\n        opacity: 1\n    }\n"], ["\n    from {\n        opacity: 0\n    }\n    to {\n        opacity: 1\n    }\n"])));
export var modalVideoInner = keyframes(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\tfrom {\n\t\ttransform: translate(0, 100px)\n\t}\n\tto {\n\t\ttransform: translate(0, 0)\n\t}\n"], ["\n\tfrom {\n\t\ttransform: translate(0, 100px)\n\t}\n\tto {\n\t\ttransform: translate(0, 0)\n\t}\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
