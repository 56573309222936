var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "clsx";
import { Navigation, Autoplay, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMount } from "@/Hooks";
import { StyledWrap } from "./style";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./swiper.css";
var SwiperSlider = function (_a) {
    var options = _a.options, prevIcon = _a.prevIcon, nextIcon = _a.nextIcon, navStyle = _a.navStyle, navPosition = _a.navPosition, dotStyle = _a.dotStyle, dotPosition = _a.dotPosition, paginationTop = _a.paginationTop, shadowSize = _a.shadowSize, children = _a.children, className = _a.className, vAlign = _a.vAlign, opacityStyle = _a.opacityStyle;
    var mounted = useMount();
    if (!mounted)
        return null;
    var sliderOptions = __assign(__assign({ modules: [Navigation, Pagination, A11y, Autoplay], slidesPerView: 3, spaceBetween: 30, loop: false, watchSlidesProgress: true, autoHeight: true }, options), { navigation: (options === null || options === void 0 ? void 0 : options.navigation)
            ? {
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
            }
            : false, pagination: (options === null || options === void 0 ? void 0 : options.pagination) ? { clickable: true } : false, autoplay: (options === null || options === void 0 ? void 0 : options.autoplay)
            ? {
                delay: 2500,
                disableOnInteraction: true,
            }
            : false });
    return (_jsxs(StyledWrap, __assign({ className: classNames(className, "swiper-wrap"), "$navStyle": navStyle, "$navPosition": navPosition, "$dotStyle": dotStyle, "$dotPosition": dotPosition, "$shadowSize": shadowSize, "$paginationTop": paginationTop, "$vAlign": vAlign, "$opacityStyle": opacityStyle }, { children: [_jsx(Swiper, __assign({}, sliderOptions, { children: children })), sliderOptions.navigation && (_jsxs(_Fragment, { children: [_jsx("button", __assign({ type: "button", className: "swiper-btn swiper-btn-prev" }, { children: _jsx("i", { className: prevIcon }) })), _jsx("button", __assign({ type: "button", className: "swiper-btn swiper-btn-next" }, { children: _jsx("i", { className: nextIcon }) }))] }))] })));
};
export { SwiperSlide };
SwiperSlider.defaultProps = {
    prevIcon: "fa fa-arrow-left",
    nextIcon: "fa fa-arrow-right",
    navStyle: 1,
    navPosition: 1,
    dotStyle: 1,
    dotPosition: 1,
};
export default SwiperSlider;
