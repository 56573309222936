var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import classNames from "clsx";
import { useScrollTop } from "@/Hooks";
import { ScrollTopButton } from "./style";
import { useLocation } from "react-router-dom";
var ScrollToTop = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    var stick = useScrollTop();
    var location = useLocation();
    useEffect(function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location.pathname]);
    var onClickHandler = function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    if (stick) {
        return (_jsxs(ScrollTopButton, __assign({ type: "button", className: classNames(className, "scroll-to-top"), onClick: onClickHandler, isStick: true }, props, { children: [_jsx("i", { className: "icon arrow-up fa fa-arrow-up" }), _jsx("i", { className: "icon arrow-bottom fa fa-arrow-up" })] })));
    }
    else {
        return null;
    }
};
export default ScrollToTop;
