var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { device, themeGet } from "@/Theme";
export var StyledSection = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-block-start: 55px;\n    padding-block-end: 60px;\n    ", " {\n        padding-block-start: 75px;\n        padding-block-end: 80px;\n    }\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 100px;\n    }\n"], ["\n    padding-block-start: 55px;\n    padding-block-end: 60px;\n    ", " {\n        padding-block-start: 75px;\n        padding-block-end: 80px;\n    }\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 100px;\n    }\n"])), device.medium, device.large);
export var LeftBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-block-end: 45px;\n    ", " {\n        margin-block-end: 0;\n    }\n"], ["\n    margin-block-end: 45px;\n    ", " {\n        margin-block-end: 0;\n    }\n"])), device.large);
export var StyledHeading = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-weight: 600;\n    position: relative;\n    line-height: 1.4;\n    padding-inline-start: 34px;\n    padding-inline-end: 30px;\n    span {\n        color: ", ";\n    }\n    &:before {\n        content: \"\";\n        position: absolute;\n        top: 50%;\n        left: 0px;\n        width: 4px;\n        height: 94%;\n        transform: translateY(-50%);\n        background-color: ", ";\n    }\n"], ["\n    font-weight: 600;\n    position: relative;\n    line-height: 1.4;\n    padding-inline-start: 34px;\n    padding-inline-end: 30px;\n    span {\n        color: ", ";\n    }\n    &:before {\n        content: \"\";\n        position: absolute;\n        top: 50%;\n        left: 0px;\n        width: 4px;\n        height: 94%;\n        transform: translateY(-50%);\n        background-color: ", ";\n    }\n"])), themeGet("colors.primary"), themeGet("colors.primary"));
export var StyledDesc = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-block-start: 15px;\n    font-size: 18px;\n    margin-inline-start: 34px;\n    color: ", ";\n"], ["\n    margin-block-start: 15px;\n    font-size: 18px;\n    margin-inline-start: 34px;\n    color: ", ";\n"])), themeGet("colors.text"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
