var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, themeGet, device } from "@/Theme";
import Anchor from "@/Components/UI/Anchor";
export var StyledMainMenu = styled.nav(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex-grow: 1;\n    display: flex;\n    align-items: center;\n    ", "\n    ", "\n    ", "\n"], ["\n    flex-grow: 1;\n    display: flex;\n    align-items: center;\n    ", "\n    ", "\n    ", "\n"])), function (_a) {
    var $alignment = _a.$alignment;
    return $alignment === "right" && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            justify-content: flex-end;\n        "], ["\n            justify-content: flex-end;\n        "])));
}, function (_a) {
    var $alignment = _a.$alignment;
    return $alignment === "left" && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            justify-content: flex-start;\n        "], ["\n            justify-content: flex-start;\n        "])));
}, function (_a) {
    var $alignment = _a.$alignment;
    return $alignment === "center" && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            justify-content: center;\n        "], ["\n            justify-content: center;\n        "])));
});
export var StyledNavbar = styled.ul(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n"], ["\n    display: flex;\n"])));
export var StyledNavitem = styled.li(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n    &:hover {\n        & > .submenu,\n        & > .megamenu {\n            transform: translateY(0);\n            visibility: visible;\n            opacity: 1;\n        }\n    }\n    ", "\n    ", "\n    ", "\n"], ["\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n    &:hover {\n        & > .submenu,\n        & > .megamenu {\n            transform: translateY(0);\n            visibility: visible;\n            opacity: 1;\n        }\n    }\n    ", "\n    ", "\n    ", "\n"])), function (_a) {
    var $space = _a.$space;
    return $space === 1 && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n            margin: 0 10px;\n            ", " {\n                margin: 0 20px;\n            }\n        "], ["\n            margin: 0 10px;\n            ", " {\n                margin: 0 20px;\n            }\n        "])), device.xxlarge);
}, function (_a) {
    var $space = _a.$space;
    return $space === 2 && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n            margin: 0 20px;\n        "], ["\n            margin: 0 20px;\n        "])));
}, function (_a) {
    var $alignment = _a.$alignment;
    return $alignment === "right" && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n            &:last-of-type {\n                & > .submenu {\n                    ", " {\n                        left: auto;\n                        right: 0;\n                    }\n                    ", " {\n                        left: 0;\n                        right: auto;\n                    }\n                }\n            }\n        "], ["\n            &:last-of-type {\n                & > .submenu {\n                    ", " {\n                        left: auto;\n                        right: 0;\n                    }\n                    ", " {\n                        left: 0;\n                        right: auto;\n                    }\n                }\n            }\n        "])), device.large, device.xxlarge);
}, function (_a) {
    var $subitem = _a.$subitem;
    return !$subitem && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n            &:first-of-type {\n                margin-left: 0;\n            }\n        "], ["\n            &:first-of-type {\n                margin-left: 0;\n            }\n        "])));
}, function (_a) {
    var $subitem = _a.$subitem;
    return $subitem && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n            margin: 9px 30px;\n        "], ["\n            margin: 9px 30px;\n        "])));
}, function (_a) {
    var $megitem = _a.$megitem;
    return $megitem && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n            margin: 9px 0;\n        "], ["\n            margin: 9px 0;\n        "])));
}, function (_a) {
    var hasSubmenu = _a.hasSubmenu;
    return hasSubmenu && css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n            position: relative;\n        "], ["\n            position: relative;\n        "])));
});
export var StyledNavlink = styled(Anchor)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    display: block;\n    padding-inline: 2px;\n    padding-block: 29px;\n    position: relative;\n    font-size: 16px;\n    font-weight: 500;\n    text-wrap: nowrap;\n    line-height: 1.18;\n    transition: all 0s ease-in-out;\n\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    .icon {\n        font-size: 14px !important;\n        margin-left: 5px;\n    }\n    ", "\n"], ["\n    display: block;\n    padding-inline: 2px;\n    padding-block: 29px;\n    position: relative;\n    font-size: 16px;\n    font-weight: 500;\n    text-wrap: nowrap;\n    line-height: 1.18;\n    transition: all 0s ease-in-out;\n\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    .icon {\n        font-size: 14px !important;\n        margin-left: 5px;\n    }\n    ", "\n"])), function (_a) {
    var $bottomLine = _a.$bottomLine;
    return $bottomLine && css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n            &:before {\n                content: \"\";\n                width: 0;\n                height: 3px;\n                bottom: 0;\n                position: absolute;\n                left: 0;\n                transition: all 0.3s ease-in-out;\n            }\n            &:hover {\n                &:before {\n                    width: 100%;\n                    left: 0;\n                }\n            }\n        "], ["\n            &:before {\n                content: \"\";\n                width: 0;\n                height: 3px;\n                bottom: 0;\n                position: absolute;\n                left: 0;\n                transition: all 0.3s ease-in-out;\n            }\n            &:hover {\n                &:before {\n                    width: 100%;\n                    left: 0;\n                }\n            }\n        "])));
}, function (_a) {
    var $color = _a.$color;
    return $color === "light" && css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n            color: rgba(255, 255, 255, 0.7);\n            &:before {\n                background-color: #ffffff;\n            }\n            &:hover {\n                color: #ffff;\n            }\n        "], ["\n            color: rgba(255, 255, 255, 0.7);\n            &:before {\n                background-color: #ffffff;\n            }\n            &:hover {\n                color: #ffff;\n            }\n        "])));
}, function (_a) {
    var $color = _a.$color;
    return $color === "white" && css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n            color: #ffffff;\n            &:before {\n                background-color: #ffffff;\n            }\n            &:hover {\n                color: #ffffff;\n            }\n        "], ["\n            color: #ffffff;\n            &:before {\n                background-color: #ffffff;\n            }\n            &:hover {\n                color: #ffffff;\n            }\n        "])));
}, function (_a) {
    var $color = _a.$color;
    return $color === "dark" && css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n            color: #000;\n            &:before {\n                background-color: ", ";\n            }\n            &:hover {\n                color: ", ";\n            }\n        "], ["\n            color: #000;\n            &:before {\n                background-color: ", ";\n            }\n            &:hover {\n                color: ", ";\n            }\n        "])), themeGet("colors.primary"), themeGet("colors.primary"));
}, function (_a) {
    var $vSpace = _a.$vSpace;
    return $vSpace === 2 && css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n            padding-block: 21px;\n        "], ["\n            padding-block: 21px;\n        "])));
}, function (_a) {
    var $sublink = _a.$sublink;
    return $sublink && css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n            display: inline-block;\n            padding: 0;\n            padding-bottom: 4px;\n            color: #575757;\n            font-weight: 400;\n            transition: 0s;\n            &:before {\n                background-color: ", ";\n                height: 1px;\n            }\n            &:hover {\n                color: ", ";\n            }\n        "], ["\n            display: inline-block;\n            padding: 0;\n            padding-bottom: 4px;\n            color: #575757;\n            font-weight: 400;\n            transition: 0s;\n            &:before {\n                background-color: ", ";\n                height: 1px;\n            }\n            &:hover {\n                color: ", ";\n            }\n        "])), themeGet("colors.primary"), themeGet("colors.primary"));
});
export var StyledSubmenu = styled.ul(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    position: absolute;\n    top: 100%;\n    left: 0;\n    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);\n    border-bottom: 3px solid ", ";\n    background-color: #ffffff;\n    transform: translateY(50px);\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    transition-delay: 0.2s;\n    transition-duration: 0.4s;\n    visibility: hidden;\n    opacity: 0;\n    min-width: 240px;\n    padding: 20px 0;\n    z-index: 9;\n"], ["\n    position: absolute;\n    top: 100%;\n    left: 0;\n    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);\n    border-bottom: 3px solid ", ";\n    background-color: #ffffff;\n    transform: translateY(50px);\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    transition-delay: 0.2s;\n    transition-duration: 0.4s;\n    visibility: hidden;\n    opacity: 0;\n    min-width: 240px;\n    padding: 20px 0;\n    z-index: 9;\n"])), themeGet("colors.primary"));
export var StyledMegamenu = styled.ul(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n    position: absolute;\n    top: 100%;\n    left: 0;\n    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);\n    border-bottom: 3px solid ", ";\n    background-color: #ffffff;\n    transform: translateY(50px);\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    transition-delay: 0.2s;\n    transition-duration: 0.4s;\n    visibility: hidden;\n    opacity: 0;\n    z-index: 9;\n\n    min-width: 980px;\n    width: 100%;\n    padding: 35px 20px 30px;\n    display: flex;\n    justify-content: space-around;\n\n    & > li {\n        flex-basis: 22%;\n        padding-left: 15px;\n        padding-right: 15px;\n    }\n"], ["\n    position: absolute;\n    top: 100%;\n    left: 0;\n    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);\n    border-bottom: 3px solid ", ";\n    background-color: #ffffff;\n    transform: translateY(50px);\n    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);\n    transition-delay: 0.2s;\n    transition-duration: 0.4s;\n    visibility: hidden;\n    opacity: 0;\n    z-index: 9;\n\n    min-width: 980px;\n    width: 100%;\n    padding: 35px 20px 30px;\n    display: flex;\n    justify-content: space-around;\n\n    & > li {\n        flex-basis: 22%;\n        padding-left: 15px;\n        padding-right: 15px;\n    }\n"])), themeGet("colors.primary"));
export var StyledMegaTitle = styled.h2(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    font-size: 14px;\n    margin-bottom: 20px;\n    color: ", ";\n"], ["\n    font-size: 14px;\n    margin-bottom: 20px;\n    color: ", ";\n"])), themeGet("colors.heading"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23;
