var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SectionTitle from "@/Components/UI/SectionTitle";
import Accordion from "@/Components/UI/Accordion";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import { SectionWrap } from "./style";
import { faqs } from "@/Data";
var FaqArea = function (_a) {
    var length = _a.length;
    return (_jsx(SectionWrap, { children: _jsxs(Container, { children: [_jsx(SectionTitle, { mb: "44px", title: "Frequently Asked Questions" }), _jsx(Row, { children: _jsx(Col, __assign({ lg: 12 }, { children: _jsx(Accordion, { data: faqs.filter(function (x, i) { return i <= length; }) }) })) })] }) }));
};
export default FaqArea;
