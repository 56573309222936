var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import SectionTitle from "@/Components/UI/SectionTitle";
import Button from "@/Components/UI/Button";
import Image from "@/Components/UI/Image";
import { ResourcesWrapper, LeftBox, StyledBG } from "./style";
var CallToActionTwo = function () {
    return (_jsxs(ResourcesWrapper, { children: [_jsx(StyledBG, { children: _jsx(Image, { src: "Background/cta-bg-2.png", alt: "CTA BG" }) }), _jsx(Container, __assign({ fluid: true }, { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsx(Col, __assign({ lg: { span: 6, order: 1 }, xs: { order: 2, span: 12 } }, { children: _jsxs(LeftBox, { children: [_jsx(SectionTitle, { mb: "30px", textAlign: ["center", null, null, "left"], title: "Product Brochure", subtitle: "Discover the Comprehensive Solution by Align Business Systems</span>" }), _jsx(Button, __assign({ download: "Business Flow - Product Brochure", downloadFile: require('@/Assets/Product Brochure.pdf').default }, { children: "Download Now" }))] }) })), _jsx(Col, __assign({ lg: { span: 6, order: 2 }, xs: { order: 1, span: 12 }, mb: ["30px"], textAlign: ["center"] }, { children: _jsx(Image, { className: "profile", src: "profile.png", alt: "Cta", styles: { height: "500px" } }) }))] })) }))] }));
};
export default CallToActionTwo;
