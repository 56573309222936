var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from '@/Routes';
import { ThemeProvider } from '@/Theme';
import { theme } from '@/Theme/theme';
// import '@/Assets/Fonts/font-awesome/font-awesome.css';
import '@/Assets/Css/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalStyle } from '@/Assets/Css/main-style';
import Transition from '@/Components/Transition';
import ScrollToTop from '@/Components/UI/ScrollToTop';
var App = function () {
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(Transition, __assign({ location: location }, { children: _jsxs("div", __assign({ className: "wrapper" }, { children: [_jsx(GlobalStyle, {}), _jsxs(BrowserRouter, { children: [_jsx(AppRoutes, {}), _jsx(ScrollToTop, {})] })] })) })) })));
};
ReactDOM.render(_jsx(App, {}), document.getElementById('root'));
