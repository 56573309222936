var menu = [
    {
        id: "menu-1",
        text: "Home",
        path: "/"
    },
    {
        id: "menu-2",
        text: "About Us",
        path: "/about-us"
    },
    {
        id: "menu-3",
        text: "Solution Highlights",
        path: "/solution-highlights"
    },
    {
        id: "menu-4",
        text: "Features",
        path: "/features"
    },
    {
        id: "menu-5",
        text: "Documentation",
        path: "https://documentation.businessflo.com/"
    },
    {
        id: "menu-6",
        text: "FAQs",
        path: "/faqs"
    },
    {
        id: "menu-7",
        text: "Clients",
        path: "/clients"
    },
    {
        id: "menu-8",
        text: "Advisors",
        path: "/advisors"
    },
    {
        id: "menu-9",
        text: "Partners",
        path: "/partners"
    },
    {
        id: "menu-10",
        text: "Careers",
        path: "/careers"
    },
    {
        id: "menu-11",
        text: "Events",
        path: "/events"
    },
    {
        id: "menu-12",
        text: "Contact Us",
        path: "/contact-us"
    },
];
export default menu;
