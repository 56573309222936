var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import Anchor from "@/Components/UI/Anchor";
import Button from "@/Components/UI/Button";
import { ConatactWrapper, ContactInfoBox, StyledHeading, StyledText, StyledInfoTitle, StyledBG, } from "./style";
import Image from "../UI/Image";
var ContactArea = function () {
    return (_jsxs(ConatactWrapper, { children: [_jsx(StyledBG, { children: _jsx(Image, { src: "Background/contact-bg.jpg", alt: "Contact BG" }) }), _jsx(Container, { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsxs(Col, __assign({ lg: 6 }, { children: [_jsxs(StyledHeading, __assign({ as: "h3" }, { children: ["Let\u2019s talk about innovative solutions,", _jsx("span", { children: " business automation " }), "and how we can help you achieve your business goals."] })), _jsx(StyledText, { children: "Connect with us to unlock a detailed analysis and assessment of your plan, opening up endless possibilities for your success." })] })), _jsx(Col, __assign({ lg: 6 }, { children: _jsxs(ContactInfoBox, { children: [_jsx("i", { className: "icon fas fa-phone" }), _jsx(StyledInfoTitle, __assign({ as: "h6" }, { children: "CALL US NOW!" })), _jsx("h2", { children: _jsx(Anchor, __assign({ path: "/", color: "#002fa6", hover: { layout: 2, color: "#002fa6" } }, { children: "+92.317.3822206 +92.317.3822207" })) }), _jsx(Button, __assign({ path: "/contact-us", mt: "20px", minWidth: "230px", color: "primary", hoverStyle: "three" }, { children: "Contact Us" }))] }) }))] })) })] }));
};
export default ContactArea;
