var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Image from "@/Components/UI/Image";
import { Container } from "@/Components/UI/Wrapper";
import Button from "@/Components/UI/Button";
import { StyledSection, StyledButtonWrap, StyledBG, StyledHeading, StyledText, StyledInfoTitle, ContentBox, } from "./style";
var SoftwareTechnologyOne = function () {
    return (_jsxs(StyledSection, { children: [_jsx(StyledBG, { children: _jsx(Image, { src: "Background/soft-s5-bg.webp", alt: "contact BG" }) }), _jsx(Container, { children: _jsxs(ContentBox, { children: [_jsx(StyledInfoTitle, __assign({ as: "h6" }, { children: "Our Technology" })), _jsxs(StyledHeading, __assign({ as: "h3" }, { children: ["Powering BusinessFlo with ", _jsx("span", { children: "Advanced Technologies" })] })), _jsx(StyledText, { children: "BusinessFlo is meticulously crafted using a powerful combination of modern technologies. Our application is built with React, TypeScript, and C#, ensuring a robust and scalable foundation. Leveraging REST API architecture, we provide seamless integration capabilities for both on-premises and cloud deployment options. The application is backed by the reliability and performance of SQL Server, empowering you with secure and efficient data management. Additionally, our reporting module is powered by Crystal Reports, offering comprehensive and insightful reporting functionalities. Experience the synergy of these cutting-edge technologies as BusinessFlo revolutionizes your business processes and drives success." }), _jsxs(StyledButtonWrap, { children: [_jsx(Button, __assign({ variant: "outlined", color: "light" }, { children: "REACT JS" })), _jsx(Button, __assign({ variant: "outlined", color: "light" }, { children: "TYPESCRIPT" })), _jsx(Button, __assign({ variant: "outlined", color: "light" }, { children: "C#" })), _jsx(Button, __assign({ variant: "outlined", color: "light" }, { children: "REST API" })), _jsx(Button, __assign({ variant: "outlined", color: "light" }, { children: "SQL Server" })), _jsx(Button, __assign({ variant: "outlined", color: "light" }, { children: "CRYSTAL REPORT" }))] })] }) })] }));
};
export default SoftwareTechnologyOne;
