var pageHeader = {
    title: "Solution Highlights",
    path: "/solution-highlights"
};
var otherCapabilities = {
    title: 'Other Capabilities',
    items: [
        {
            icon: "Icons/production.png",
            title: "Production",
        },
        {
            icon: "Icons/plant-maintenance.png",
            title: "Plant Maintenance",
        },
        {
            icon: "Icons/service-management.png",
            title: "Service Management",
        },
        {
            icon: "Icons/hr-management.png",
            title: "Human Resource Management",
        },
        {
            icon: "Icons/property-management.png",
            title: "Property Management",
        },
    ]
};
export var solutionHighlights = {
    pageHeader: pageHeader,
    otherCapabilities: otherCapabilities,
};
export default solutionHighlights;
