var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import SectionTitle from "@/Components/UI/SectionTitle";
import { SectionWrap, JobList, JobItem, JobTitle, JobType, JobDesc, } from "./style";
var ListingArea = function (_a) {
    var data = _a.data;
    var title = data.title, items = data.items;
    return (_jsx(SectionWrap, { children: _jsxs(Container, { children: [title && (_jsx(SectionTitle, { mb: ["30px", null, "45px"], maxWidth: ["700px"], mx: "auto", subtitle: title })), _jsx(JobList, { children: items === null || items === void 0 ? void 0 : items.map(function (item, i) {
                        var isEven = i % 2 === 0;
                        return (_jsx(JobItem, __assign({ isEven: isEven }, { children: _jsxs(Row, { children: [_jsxs(Col, __assign({ lg: 3 }, { children: [_jsx(JobTitle, { children: item.title }), _jsx(JobType, { children: item.subTitle })] })), _jsx(Col, __assign({ lg: 9 }, { children: _jsx(JobDesc, { dangerouslySetInnerHTML: { __html: (item === null || item === void 0 ? void 0 : item.text) || '' } }) }))] }) }), i));
                    }) })] }) }));
};
export default ListingArea;
