var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageHeaderWrap, StyledBG } from "./style";
import Image from "@/Components/UI/Image";
import { useLocation } from "react-router-dom";
var PageHeaderTwo = function (_a) {
    var data = _a.data;
    var location = useLocation();
    var title = data.title, path = data.path;
    return (_jsxs(PageHeaderWrap, { children: [_jsx(StyledBG, { children: _jsx(Image, { src: "Background/title-bar-01-bg.jpg", alt: "CTA BG", layout: "fullWidth", placeholder: "tracedSVG" }) }), _jsx("span", __assign({ className: "breadcrumb__title" }, { children: title })), _jsx("nav", __assign({ className: "breadcrumb", "aria-label": "Breadcrumb" }, { children: _jsxs("ol", __assign({ className: "breadcrumb__list" }, { children: [_jsx("li", __assign({ className: "breadcrumb__list__item" }, { children: _jsx("a", __assign({ className: "breadcrumb__link", href: "/" }, { children: "Home" })) })), _jsx("span", __assign({ className: "breadcrumb__separator", "aria-hidden": "true" }, { children: "/" })), _jsx("li", __assign({ className: "breadcrumb__list__item" }, { children: _jsx("a", __assign({ "aria-current": "page", className: "breadcrumb__link breadcrumb__link__active", href: path }, { children: title })) }))] })) }))] }));
};
export default PageHeaderTwo;
