var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { device, themeGet } from "@/Theme";
export var SectionWrap = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-block-start: 60px;\n    padding-block-end: 52px;\n    ", " {\n        padding-block-start: 80px;\n        padding-block-end: 72px;\n    }\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 92px;\n    }\n"], ["\n    padding-block-start: 60px;\n    padding-block-end: 52px;\n    ", " {\n        padding-block-start: 80px;\n        padding-block-end: 72px;\n    }\n    ", " {\n        padding-block-start: 100px;\n        padding-block-end: 92px;\n    }\n"])), device.medium, device.large);
export var StyledTitle = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    letter-spacing: -1px;\n    line-height: 40px;\n    color: #3378b1;\n    font-size: 25px;\n    margin: auto;\n    margin-top: 0;\n    margin-bottom: 25px;\n    width: fit-content;\n"], ["\n    letter-spacing: -1px;\n    line-height: 40px;\n    color: #3378b1;\n    font-size: 25px;\n    margin: auto;\n    margin-top: 0;\n    margin-bottom: 25px;\n    width: fit-content;\n"])));
export var StyledSubTitle = styled.h4(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    letter-spacing: -1px;\n    line-height: 40px;\n    color: #575757;\n    font-size: 24px;\n    margin: auto;\n    margin-top: 0;\n    margin-bottom: 25px;\n    width: fit-content;\n    text-transform: uppercase;\n\n    &::after{\n        width: 30px;\n        height: 2px;\n        display: block;\n        margin: auto;\n        background-color: ", ";\n        content: \"\";\n    }\n"], ["\n    letter-spacing: -1px;\n    line-height: 40px;\n    color: #575757;\n    font-size: 24px;\n    margin: auto;\n    margin-top: 0;\n    margin-bottom: 25px;\n    width: fit-content;\n    text-transform: uppercase;\n\n    &::after{\n        width: 30px;\n        height: 2px;\n        display: block;\n        margin: auto;\n        background-color: ", ";\n        content: \"\";\n    }\n"])), themeGet("colors.primary"));
export var StyledText = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    text-align: justify;\n"], ["\n    text-align: justify;\n"])));
export var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 20px;\n"], ["\n    padding: 20px;\n"])));
export var StyledListHeader = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n   font-weight: bold;\n   color: ", ";\n   margin-bottom: 0;\n"], ["\n   font-weight: bold;\n   color: ", ";\n   margin-bottom: 0;\n"])), themeGet("colors.primary"));
export var StyledListText = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    text-align: justify;\n"], ["\n    text-align: justify;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
