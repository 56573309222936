var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { breakpoints } from "./index";
export var theme = {
    colors: {
        primary: "#0a2e58",
        text: "#575757",
        heading: "#333333",
        border: "#ededed",
        borderTwo: "#f8f8f8",
        borderDash: "#dddddd",
        white: "#ffffff",
        black: "#000000",
        secondary: "#d2a98e",
        success: "#38cb89",
        info: "#17a2b8",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        background: "#ffffff",
        gray: {
            100: "#F8F8F8",
            200: "#F6FAFE",
            300: "#d6d6d6",
            400: "#eeeeee",
            500: "#fafafa",
            600: "#f5f7fd",
            700: "#7e7e7e",
        },
        silver: "#ababab",
        silverTwo: "#cccccc",
        kimberly: "#6d70a6",
        spring: "#F6F2ED",
        pattens: "#def0ff",
        yellow: "#ffb944",
        whiteSmoke: "#f5f5f5",
        klein: "#002fa6",
        water: "#e7edfa",
    },
    lineHeights: {
        body: 1.74,
        heading: 1.41,
    },
    fonts: {
        body: "'Inter', sans-serif",
        heading: "'Inter', sans-serif",
        fontAwesome: "Font Awesome 5 Free",
        fontAwesomeBrand: "Font Awesome 5 Brands",
    },
    fontSizes: {
        body: "15px",
        h1: "56px",
        h2: "48px",
        h3: "40px",
        h4: "34px",
        h5: "24px",
        h6: "18px",
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    radii: {
        sm: "3px",
        md: "5px",
        lg: "8px",
        round: "50%",
        pill: "500px",
    },
    shadows: {
        default: "0 0 40px rgba(51, 51, 51, 0.1)",
        sm: "0 0 20px rgba(51, 51, 51, 0.1)",
        md: "0 18px 40px rgba(51, 51, 51, 0.1)",
        lg: "0 0 40px 5px rgba(51, 51, 51, 0.1)",
        xl: "0 41px 43px rgba(51, 51, 51, 0.07)",
        input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
    },
    transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    breakpoints: __spreadArray([], __read(breakpoints), false),
};
