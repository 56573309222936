var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "clsx";
import { ButtonWrap, AnchorButton, HashButton, LinkButton } from "./style";
import { saveAs } from 'file-saver';
var Button = function (_a) {
    var children = _a.children, icon = _a.icon, className = _a.className, path = _a.path, type = _a.type, onClick = _a.onClick, stickyBtn = _a.stickyBtn, icondistance = _a.icondistance, shadow = _a.shadow, size = _a.size, color = _a.color, shape = _a.shape, variant = _a.variant, disableHover = _a.disableHover, hoverStyle = _a.hoverStyle, fullwidth = _a.fullwidth, iconposition = _a.iconposition, iconsize = _a.iconsize, download = _a.download, downloadFile = _a.downloadFile, imgbutton = _a.imgbutton, rest = __rest(_a, ["children", "icon", "className", "path", "type", "onClick", "stickyBtn", "icondistance", "shadow", "size", "color", "shape", "variant", "disableHover", "hoverStyle", "fullwidth", "iconposition", "iconsize", "download", "downloadFile", "imgbutton"]);
    var internal = /^\/(?!\/)/.test(path);
    var btn = {
        size: size || "medium",
        color: color || "primary",
        shape: shape || "rounded",
        variant: variant || "contained",
        disableHover: disableHover || false,
        hoverStyle: hoverStyle || "one",
        fullwidth: fullwidth || false,
        iconposition: iconposition || "right",
        iconsize: iconsize || "22px",
    };
    var onClickHandler = function () {
        if (download && downloadFile) {
            var fileUrl = downloadFile;
            fetch(fileUrl)
                .then(function (response) { return response.blob(); })
                .then(function (blob) {
                saveAs(blob, download);
            })
                .catch(function (error) {
                console.log('Error downloading the file:', error);
            });
        }
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    if (path) {
        var isHash = path.startsWith("#");
        if (internal) {
            return (_jsxs(LinkButton, __assign({ to: path, className: classNames(className, "mt-btn"), "$color": btn.color, "$size": btn.size, "$shape": btn.shape, "$variant": btn.variant, "$disableHover": btn.disableHover, "$hoverStyle": btn.hoverStyle, "$fullwidth": btn.fullwidth, "$iconposition": btn.iconposition, "$icondistance": icondistance, "$iconsize": btn.iconsize, "$stickyBtn": stickyBtn, "$imgbutton": imgbutton, "$shadow": shadow }, rest, { children: [btn.iconposition === "left" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) })), children, btn.iconposition === "right" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) }))] })));
        }
        else if (isHash) {
            return (_jsxs(HashButton, __assign({ to: path, className: classNames(className, "mt-btn"), "$color": btn.color, "$size": btn.size, "$shape": btn.shape, "$variant": btn.variant, "$disableHover": btn.disableHover, "$hoverStyle": btn.hoverStyle, "$fullwidth": btn.fullwidth, "$iconposition": btn.iconposition, "$icondistance": icondistance, "$iconsize": btn.iconsize, "$stickyBtn": stickyBtn, "$imgbutton": imgbutton, "$shadow": shadow }, rest, { children: [btn.iconposition === "left" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) })), children, btn.iconposition === "right" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) }))] })));
        }
        else {
            return (_jsxs(AnchorButton, __assign({ href: path, className: classNames(className, "mt-btn"), "$color": btn.color, "$size": btn.size, "$shape": btn.shape, "$variant": btn.variant, "$disableHover": btn.disableHover, "$hoverStyle": btn.hoverStyle, "$fullwidth": btn.fullwidth, "$iconposition": btn.iconposition, "$icondistance": icondistance, "$iconsize": btn.iconsize, "$stickyBtn": stickyBtn, "$imgbutton": imgbutton, "$shadow": shadow, target: "__blank" }, rest, { children: [btn.iconposition === "left" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) })), children, btn.iconposition === "right" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) }))] })));
        }
    }
    else {
        return (_jsxs(ButtonWrap, __assign({ type: type, className: classNames(className, "mt-btn"), "$color": btn.color, "$size": btn.size, "$shape": btn.shape, "$variant": btn.variant, "$disableHover": btn.disableHover, "$hoverStyle": btn.hoverStyle, "$fullwidth": btn.fullwidth, "$hasIcon": Boolean(icon), "$iconposition": btn.iconposition, "$icondistance": icondistance, "$iconsize": btn.iconsize, "$stickyBtn": stickyBtn, "$imgbutton": imgbutton, "$shadow": shadow, onClick: onClickHandler }, rest, { children: [btn.iconposition === "left" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) })), children, btn.iconposition === "right" && icon && (_jsx("i", { className: "btn-icon ".concat(icon) }))] })));
    }
};
export default Button;
