var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, themeGet, device } from "@/Theme";
export var TeamMemberInner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-inline: auto;\n    /* ", " {\n        .gatsby-image-wrapper {\n            max-width: 100% !important;\n        }\n    } */\n"], ["\n    margin-inline: auto;\n    /* ", " {\n        .gatsby-image-wrapper {\n            max-width: 100% !important;\n        }\n    } */\n"])), device.small);
export var TeamMemberImage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    ", "\n"], ["\n    position: relative;\n    ", "\n"])), function (_a) {
    var $layout = _a.$layout;
    return ($layout === 2 || $layout === 3) && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        margin: auto;\n        width: 150px;\n    "], ["\n        margin: auto;\n        width: 150px;\n    "])));
});
export var TeamMemberSocialWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n    right: 20px;\n    padding-block: 11px;\n    opacity: 0;\n    visibility: hidden;\n    text-align: center;\n    background-color: rgb(0, 47, 166);\n    transition: ", ";\n"], ["\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n    right: 20px;\n    padding-block: 11px;\n    opacity: 0;\n    visibility: hidden;\n    text-align: center;\n    background-color: rgb(0, 47, 166);\n    transition: ", ";\n"])), themeGet("transition"));
export var TeamMemberInfo = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-block-start: 20px;\n    text-align: justify;\n"], ["\n    margin-block-start: 20px;\n    text-align: justify;\n"])));
export var TeamMemberName = styled.h5(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    text-align: center;\n    margin-bottom: 10px;\n    \n    ", " {\n        font-size: 20px;\n    }\n    ", "\n"], ["\n    text-align: center;\n    margin-bottom: 10px;\n    \n    ", " {\n        font-size: 20px;\n    }\n    ", "\n"])), device.medium, function (_a) {
    var $layout = _a.$layout;
    return $layout === 3 && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n            font-size: 18px;\n            line-height: 1.78;\n        "], ["\n            font-size: 18px;\n            line-height: 1.78;\n        "])));
});
export var TeamMemberDesignation = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
export var TeamMemberWrap = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    ", "\n    ", "\n    &:hover {\n        ", " {\n            opacity: 1;\n            visibility: visible;\n            transform: translateY(0);\n        }\n    }\n"], ["\n    ", "\n    ", "\n    &:hover {\n        ", " {\n            opacity: 1;\n            visibility: visible;\n            transform: translateY(0);\n        }\n    }\n"])), function (_a) {
    var $layout = _a.$layout;
    return $layout === 1 && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n            ", " {\n                max-width: 310px;\n            }\n        "], ["\n            ", " {\n                max-width: 310px;\n            }\n        "])), TeamMemberInner);
}, function (_a) {
    var $layout = _a.$layout;
    return $layout === 2 && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n            ", " {\n                max-width: 350px;\n            }\n        "], ["\n            ", " {\n                max-width: 350px;\n            }\n        "])), TeamMemberInner);
}, TeamMemberSocialWrap);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
