var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Image from "@/Components/UI/Image";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import { PageHeaderWrap, StyledTitle, StyledDesc, StyledBG } from "./style";
var PageHeader = function (_a) {
    var data = _a.data;
    var image = data.image, title = data.title, text = data.text;
    return (_jsxs(PageHeaderWrap, { children: [image && (_jsx(StyledBG, { children: _jsx(Image, { className: "h-100 w-100", src: image, alt: "Page Header" }) })), _jsx(Container, { children: _jsx(Row, __assign({ textAlign: "center" }, { children: _jsxs(Col, __assign({ lg: 8, mx: "auto" }, { children: [title && (_jsx(StyledTitle, __assign({ as: "h2" }, { children: title }))), text && (_jsx(StyledDesc, { children: text }))] })) })) })] }));
};
export default PageHeader;
