var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Helmet } from "react-helmet";
import { site } from "@/Data";
var SEO = function (_a) {
    var title = _a.title;
    var titleTemplate = site.titleTemplate, description = site.description, image = site.image, siteLanguage = site.siteLanguage, siteUrl = site.siteUrl;
    var siteTitle = "".concat(titleTemplate, " | ").concat(title);
    var siteUrlWithoutTrailingSlash = siteUrl.replace(/\/$/, "");
    var bannerImage = "".concat(siteUrlWithoutTrailingSlash, "/").concat(image);
    var imgWidth = 875;
    var imgHeight = 554;
    var basSchema = [
        {
            "@type": "Organization",
            "@id": "".concat(siteUrlWithoutTrailingSlash, "/#organization"),
            name: "".concat(siteTitle),
            url: siteUrlWithoutTrailingSlash,
            logo: {
                "@type": "ImageObject",
                url: bannerImage,
            },
        },
        {
            "@type": "WebSite",
            "@id": "".concat(siteUrlWithoutTrailingSlash, "/#website"),
            url: siteUrlWithoutTrailingSlash,
            name: "".concat(siteTitle),
            publisher: {
                "@id": "".concat(siteUrlWithoutTrailingSlash, "/#organization"),
            },
            inLanguage: siteLanguage,
            potentialAction: {
                "@type": "SearchAction",
                target: "".concat(siteUrlWithoutTrailingSlash, "/?s={search_term_string}"),
                "query-input": "required name=search_term_string",
            },
        },
    ];
    var schemaOrgWebPage = {
        "@context": "http://schema.org",
        "@graph": __spreadArray([], __read(basSchema), false),
    };
    return (_jsxs(Helmet, __assign({ htmlAttributes: {
            lang: siteLanguage,
        } }, { children: [_jsx("title", { children: siteTitle }), _jsx("meta", { name: "description", content: description }), _jsx("meta", { name: "image", content: bannerImage }), _jsx("link", { rel: "canonical", href: siteUrl }), _jsx("meta", { name: "robots", content: "index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" }), _jsx("meta", { property: "og:locale", content: siteLanguage }), _jsx("meta", { property: "og:type", content: "website" }), _jsx("meta", { property: "og:url", content: siteUrl }), _jsx("meta", { property: "og:title", content: siteTitle }), _jsx("meta", { property: "og:description", content: description }), _jsx("meta", { property: "og:image", content: bannerImage }), _jsx("meta", { property: "og:image:secure_url", content: bannerImage }), _jsx("meta", { property: "og:image:width", content: "".concat(imgWidth, "px") }), _jsx("meta", { property: "og:image:height", content: "".concat(imgHeight, "px") }), _jsx("meta", { property: "og:image:alt", content: siteTitle }), _jsx("meta", { property: "og:image:type", content: "image/png" }), _jsx("script", __assign({ type: "application/ld+json" }, { children: JSON.stringify(schemaOrgWebPage) }))] })));
};
export default SEO;
