var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import Heading from "@/Components/UI/Heading";
import Text from "@/Components/UI/Text";
import Button from "@/Components/UI/Button";
import TeamMember from "@/Components/TeamMember";
import { TeamWrapper, SectionTitle, ButtonWrap } from "./style";
var TeamMemberSectionOne = function (_a) {
    var data = _a.data;
    var title = data.title, text = data.text, items = data.items, buttons = data.buttons;
    return (_jsx(TeamWrapper, { children: _jsx(Container, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ lg: 4 }, { children: _jsxs(SectionTitle, { children: [_jsx(Heading, { as: "h3", child: { color: "primary" }, children: title }), _jsx(Text, __assign({ mt: "30px" }, { children: text })), _jsx(ButtonWrap, { children: buttons === null || buttons === void 0 ? void 0 : buttons.map(function (_a, idx) {
                                        var path = _a.path, content = _a.content, restProps = __rest(_a, ["path", "content"]);
                                        return (_jsx(Button, __assign({ m: "7px", path: path }, restProps, { children: content }), idx));
                                    }) })] }) })), _jsx(Col, __assign({ lg: 8 }, { children: _jsx(Row, { children: items === null || items === void 0 ? void 0 : items.map(function (item, i) { return (_jsx(Col, __assign({ md: 6, mb: ["30px", null, "60px"] }, { children: _jsx(TeamMember, { image: item.image, name: item.title, designation: item.text }) }), "team-".concat(i))); }) }) }))] }) }) }));
};
export default TeamMemberSectionOne;
