var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { themeGet } from "@/Theme";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { InputStyles, SuccessInputStyles, WarningInputStyles, ErrorInputStyles, } from "../style";
export var StyledSelect = styled(PhoneInputWithCountrySelect)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-color: ", ";\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    background: ", ";\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n\n    .PhoneInputCountry {\n        padding-right: 5px;\n    }\n\n    .PhoneInputInput {\n        background-color: ", ";\n        border: 0;\n        border-left: 1px solid #cbcbcb;\n        padding-left: 10px;\n    }\n"], ["\n    border-color: ", ";\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    background: ", ";\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n\n    .PhoneInputCountry {\n        padding-right: 5px;\n    }\n\n    .PhoneInputInput {\n        background-color: ", ";\n        border: 0;\n        border-left: 1px solid #cbcbcb;\n        padding-left: 10px;\n    }\n"])), themeGet("colors.border"), function (props) { return props.bgcolor || "#f8f8f8"; }, InputStyles, function (_a) {
    var $state = _a.$state, $showState = _a.$showState, $showErrorOnly = _a.$showErrorOnly;
    return $state === "success" &&
        !!$showState &&
        !$showErrorOnly &&
        SuccessInputStyles;
}, function (_a) {
    var $state = _a.$state, $showState = _a.$showState, $showErrorOnly = _a.$showErrorOnly;
    return $state === "warning" &&
        !!$showState &&
        !$showErrorOnly &&
        WarningInputStyles;
}, function (_a) {
    var $state = _a.$state, $showState = _a.$showState, $showErrorOnly = _a.$showErrorOnly;
    return $state === "error" &&
        !!$showState &&
        !!$showErrorOnly &&
        ErrorInputStyles;
}, function (props) { return props.bgcolor || "#f8f8f8"; });
var templateObject_1;
