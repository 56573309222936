var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getClosest, getSiblings } from "@/Utils";
import { StyledMobileMenu, StyledNavbar, StyledNavitem, StyledNavlink, StyledButton, StyledSubmenu, StyledMegamenu, StyledMegatitle, } from "./style";
var MobileMenu = function (_a) {
    var menuData = _a.menuData;
    var removeClassFromChildren = function (parent) {
        for (var i = 0; i < parent.children.length; i++) {
            if (parent.children[i].tagName === "UL") {
                var child = parent.children[i].children;
                for (var j = 0; j < child.length; j++) {
                    child[j].classList.remove("submenu-open");
                }
            }
        }
    };
    var onClickHandler = function (e, selector) {
        var target = e.target;
        var parentEl = target.parentElement;
        if (parentEl.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")) {
            var element = target.classList.contains("icon") ? parentEl : target;
            var parent = getClosest(element, selector);
            var parentSiblings = getSiblings(parent);
            parentSiblings.forEach(function (sibling) {
                sibling.classList.remove("submenu-open");
                removeClassFromChildren(sibling);
            });
            removeClassFromChildren(parent);
            parent.classList.toggle("submenu-open");
        }
    };
    return (_jsx(StyledMobileMenu, { children: _jsx(StyledNavbar, { children: menuData.map(function (menu, idx) {
                var submenu = menu.subMenu ? menu.subMenu : null;
                var megamenu = menu.megaMenu ? menu.megaMenu : null;
                var menuIndex = idx;
                return (_jsxs(StyledNavitem, __assign({ hasSubmenu: submenu, hasMegamenu: megamenu, className: "menu-item", id: "menu-item-".concat(menuIndex) }, { children: [_jsx(StyledNavlink, __assign({ path: menu.path }, { children: menu.text })), (submenu || megamenu) && (_jsx(StyledButton, __assign({ className: "menu-expand", onClick: function (e) {
                                return onClickHandler(e, "#menu-item-".concat(menuIndex));
                            } }, { children: _jsx("i", { className: "icon fa fa-angle-down" }) }))), submenu && (_jsx(StyledSubmenu, __assign({ className: "submenu" }, { children: submenu.map(function (subitem, idx) {
                                var submenuLevelTwo = subitem.submenu;
                                var submenuIndex = idx;
                                return (_jsxs(StyledNavitem, __assign({ className: "menu-item", id: "submenu-item-".concat(menuIndex).concat(submenuIndex), "$inSubmenu": true }, { children: [_jsx(StyledNavlink, __assign({ path: subitem.path, "$inSubmenu": true }, { children: subitem.text })), submenuLevelTwo && (_jsx(StyledButton, __assign({ className: "menu-expand", "$inSubmenu": true, onClick: function (e) {
                                                return onClickHandler(e, "#submenu-item-".concat(menuIndex).concat(submenuIndex));
                                            } }, { children: _jsx("i", { className: "icon fa fa-angle-down" }) }))), submenuLevelTwo && (_jsx(StyledSubmenu, { children: submenuLevelTwo.map(function (subitemLevelTwo, idx) {
                                                var subsubmenuIndex = idx;
                                                return (_jsx(StyledNavitem, __assign({ className: "menu-item", id: "submenu-item-".concat(menuIndex).concat(submenuIndex).concat(subsubmenuIndex) }, { children: _jsx(StyledNavlink, __assign({ path: subitemLevelTwo.path }, { children: subitemLevelTwo.text })) }), "submenu-".concat(menu.id, "-").concat(submenuIndex, "-").concat(subsubmenuIndex)));
                                            }) }))] }), "submenu-".concat(menu.id, "-").concat(submenuIndex)));
                            }) }))), megamenu && (_jsx(StyledMegamenu, __assign({ className: "megamenu" }, { children: megamenu.map(function (megaitem, idx) {
                                var megaSubmenu = megaitem.submenu;
                                var megaIndex = idx;
                                return (_jsxs(StyledNavitem, __assign({ id: "megamenu-".concat(menu.id, "-").concat(megaIndex), "$inSubmenu": true }, { children: [_jsxs(StyledMegatitle, { children: [_jsx("span", { children: megaitem.title }), _jsx(StyledButton, __assign({ className: "menu-expand", "$inSubmenu": true, onClick: function (e) {
                                                        return onClickHandler(e, "#megamenu-".concat(menu.id, "-").concat(megaIndex));
                                                    } }, { children: _jsx("i", { className: "icon fa fa-angle-down" }) }))] }), megaSubmenu && (_jsx(StyledSubmenu, __assign({ className: "submenu" }, { children: megaSubmenu.map(function (megaSubitem, i) {
                                                return (_jsx(StyledNavitem, __assign({ "$inSubmenu": true }, { children: _jsx(StyledNavlink, __assign({ "$inSubmenu": true, path: megaSubitem.path }, { children: _jsx("span", { children: megaSubitem.text }) })) }), "megasubmenu-".concat(megaIndex, "-").concat(i)));
                                            }) })))] }), "megamenu-".concat(menu.id, "-").concat(megaIndex)));
                            }) })))] }), "mainmenu-".concat(menu.id)));
            }) }) }));
};
export default MobileMenu;
