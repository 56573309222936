var pageHeader = {
    image: "Banner/banner-02.png",
    title: "Advisors",
    text: '"Great advice is the compass that guides us through the ever-changing seas of progress." - Align Business Systems'
};
var sectionOne = {
    title: "Introducing Our Esteemed <span>Advisors<span>",
    text: "As an IT service company specializing in customized web, mobile, and ERP solutions, we seek guidance from highly accomplished individuals. Our esteemed advisors provide invaluable insights and strategic direction, ensuring we deliver excellence in every project. With their support, we continuously push boundaries, embrace innovation, and empower businesses to thrive in the digital landscape.",
    items: [
        {
            title: "Muhammed Shamsheer",
            text: "Meet Muhammad Shamsheer, the CEO and Co-founder of Align Business Systems, boasting an impressive 18 years of diverse experience in the industry. With a strong technical and process-oriented background, he leads the company with a keen focus on simplicity and reliability across all aspects of our work.\n            <br/><br/>\n            In addition to his role as CEO, Shamsheer is highly regarded as a functional consultant, infusing deep business insights into our endeavors. His invaluable mentorship has been instrumental in fostering the growth and development of Align Business Systems amidst the competitive landscape of IT solutions. With a profound understanding of the industry, he deftly navigates complexities, ensuring that our services remain at the forefront of innovation and maintain top-tier quality.\n            <br/><br/>\n            Driven by unwavering dedication and a commitment to excellence, Shamsheer propels Align Business Systems to new heights. Under his exceptional guidance, we continuously shape the future of IT solutions, embracing innovation, and delivering unparalleled services to our\u00A0valued\u00A0clients.",
            image: 'Advisors/advisor-01.png'
        },
        {
            title: "Farrukh V. Junaidy",
            text: "Meet Farrukh V. Junaidy, an esteemed advisor with over 40 years of diverse experience. His strong background in financial and business management, strategic planning, and budget administration has been instrumental in shaping the success of numerous organizations. Additionally, Farrukh's expertise extends to staff training and development, contract negotiation, Mergers & Acquisitions, Due Diligence, Forensic Audit, and policy & procedural development.\n            <br/><br/>\n            Farrukh brings a wealth of knowledge to the table, with proficiency in risk management, corporate & financial restructuring, and business process reorganization. His skills in tax planning and compliance for both direct and indirect taxes, coupled with report preparation and public relations, further enhance his contributions.\n            <br/><br/>\n            As an esteemed advisor at Align Business Systems, Farrukh plays a pivotal role in honing our strategies, ensuring top-notch solutions, and delivering excellence to our valued clients. Under his mentorship, we explore new horizons and thrive in the dynamic world of IT services and\u00A0ERP\u00A0solutions.",
            image: 'Advisors/advisor-02.png'
        },
        {
            title: "Ali Akbar Abdullah",
            text: "Ali Akbar Abdullah is a distinguished Fellow Member of the Institute of Chartered Accountants of Pakistan, boasting a remarkable 22 years of hands-on experience in financial management, accounting, corporate affairs, taxation, due diligence, flotation, and merger & acquisition.\n            <br/><br/>\n            Throughout his career, Ali has been closely associated with Non-Banking Financial Institutions and Modarabas in Pakistan and overseas, holding various management positions. These roles include Group Financial Officer, Company Secretary, Head of Finance, and Head of Internal Audit.\n            <br/><br/>\n            With his extensive expertise and versatile background, Ali Akbar Abdullah brings a wealth of knowledge and insights to the table, making him a valuable asset in the field of finance and corporate\u00A0management.",
            image: 'Advisors/advisor-03.png'
        }
    ],
    buttons: [
        {
            path: "/contact-us",
            content: "Contact Us"
        },
        {
            path: "/about-us",
            content: "Learn More",
            variant: "texted",
            hoverStyle: "two",
        }
    ]
};
var callToActionSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var associates = {
    pageHeader: pageHeader,
    sectionOne: sectionOne,
    callToActionSection: callToActionSection
};
export default associates;
