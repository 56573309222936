var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { themeGet, css, space, border as borderStyles, device } from "@/Theme";
export var InputStyles = css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    width: 100%;\n    color: #000;\n    background-color: ", ";\n    font-size: 15px;\n    font-weight: 400;\n    outline: none;\n    border: 1px solid ", ";\n    border-radius: ", ";\n    padding: 3px 20px;\n    height: 56px;\n\n    ", "\n    ", "\n    &::placeholder {\n        color: ", ";\n        font-size: ", ";\n        font-family: ", ";\n    }\n    &:-ms-input-placeholder {\n        color: ", ";\n        font-size: ", ";\n        font-family: ", ";\n    }\n    &::-ms-input-placeholder {\n        color: ", ";\n        font-size: ", ";\n        font-family: ", ";\n    }\n    &:disabled,\n    &[readonly] {\n        background-color: ", ";\n    }\n\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n    ", "\n\n        \n    ", ";\n    ", ";\n"], ["\n    width: 100%;\n    color: #000;\n    background-color: ", ";\n    font-size: 15px;\n    font-weight: 400;\n    outline: none;\n    border: 1px solid ", ";\n    border-radius: ", ";\n    padding: 3px 20px;\n    height: 56px;\n\n    ", "\n    ", "\n    &::placeholder {\n        color: ", ";\n        font-size: ", ";\n        font-family: ", ";\n    }\n    &:-ms-input-placeholder {\n        color: ", ";\n        font-size: ", ";\n        font-family: ", ";\n    }\n    &::-ms-input-placeholder {\n        color: ", ";\n        font-size: ", ";\n        font-family: ", ";\n    }\n    &:disabled,\n    &[readonly] {\n        background-color: ", ";\n    }\n\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n    ", "\n\n        \n    ", ";\n    ", ";\n"])), themeGet("colors.gray.100"), themeGet("colors.gray.100"), themeGet("radii.md"), function (_a) {
    var $width = _a.$width;
    return !!$width && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            ", "\n            ", "\n        "], ["\n            ", "\n            ", "\n        "])), !Array.isArray($width) && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                width: ", ";\n            "], ["\n                width: ", ";\n            "])), $width), Array.isArray($width) && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                width: ", ";\n                ", " {\n                    width: ", ";\n                }\n                ", " {\n                    width: ", ";\n                }\n                ", " {\n                    width: ", ";\n                }\n                ", " {\n                    width: ", ";\n                }\n            "], ["\n                width: ", ";\n                ", " {\n                    width: ", ";\n                }\n                ", " {\n                    width: ", ";\n                }\n                ", " {\n                    width: ", ";\n                }\n                ", " {\n                    width: ", ";\n                }\n            "])), $width[0], device.small, $width[1], device.medium, $width[2], device.large, $width[3], device.xlarge, $width[4]));
}, function (_a) {
    var $height = _a.$height;
    return !!$height && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n            ", "\n            ", "\n        "], ["\n            ", "\n            ", "\n        "])), !Array.isArray($height) && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n                height: ", ";\n            "], ["\n                height: ", ";\n            "])), $height), Array.isArray($height) && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n                height: ", ";\n                ", " {\n                    height: ", ";\n                }\n                ", " {\n                    height: ", ";\n                }\n                ", " {\n                    height: ", ";\n                }\n                ", " {\n                    height: ", ";\n                }\n            "], ["\n                height: ", ";\n                ", " {\n                    height: ", ";\n                }\n                ", " {\n                    height: ", ";\n                }\n                ", " {\n                    height: ", ";\n                }\n                ", " {\n                    height: ", ";\n                }\n            "])), $height[0], device.small, $height[1], device.medium, $height[2], device.large, $height[3], device.xlarge, $height[4]));
}, themeGet("colors.text"), themeGet("fontSize.body"), themeGet("fonts.body"), themeGet("colors.text"), themeGet("fontSize.body"), themeGet("fonts.body"), themeGet("colors.text"), themeGet("fontSize.body"), themeGet("fonts.body"), themeGet("colors.whiteSmoke"), function (_a) {
    var $hover = _a.$hover;
    return !!$hover === false && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n            &:focus {\n                outline: 0;\n                box-shadow: none;\n                color: ", ";\n                border-color: ", ";\n            }\n        "], ["\n            &:focus {\n                outline: 0;\n                box-shadow: none;\n                color: ", ";\n                border-color: ", ";\n            }\n        "])), themeGet("colors.text"), themeGet("colors.primary"));
}, function (_a) {
    var $hover = _a.$hover;
    return $hover === 1 && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n            &:focus {\n                outline: 0;\n                box-shadow: none;\n                color: ", ";\n                border-color: ", ";\n            }\n        "], ["\n            &:focus {\n                outline: 0;\n                box-shadow: none;\n                color: ", ";\n                border-color: ", ";\n            }\n        "])), themeGet("colors.primary"), themeGet("colors.primary"));
}, function (_a) {
    var $hover = _a.$hover;
    return $hover === 2 && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n            &:focus {\n                color: #222;\n                border-color: transparent;\n                background: #fff;\n                box-shadow: ", ";\n            }\n        "], ["\n            &:focus {\n                color: #222;\n                border-color: transparent;\n                background: #fff;\n                box-shadow: ", ";\n            }\n        "])), themeGet("shadows.default"));
}, function (_a) {
    var $customStyle = _a.$customStyle;
    return $customStyle === "noborder" && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n            padding: 0;\n            color: ", ";\n            border-width: 0;\n            background-color: transparent;\n            &:focus {\n                box-shadow: none;\n                color: ", ";\n            }\n        "], ["\n            padding: 0;\n            color: ", ";\n            border-width: 0;\n            background-color: transparent;\n            &:focus {\n                box-shadow: none;\n                color: ", ";\n            }\n        "])), themeGet("colors.text"), themeGet("colors.text"));
}, function (_a) {
    var $customStyle = _a.$customStyle;
    return $customStyle === "nofocus" && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n            &:focus {\n                box-shadow: none;\n                border: none;\n            }\n        "], ["\n            &:focus {\n                box-shadow: none;\n                border: none;\n            }\n        "])));
}, space, borderStyles);
export var SuccessInputStyles = css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    border-color: ", ";\n    &:focus {\n        border-color: ", ";\n    }\n"], ["\n    border-color: ", ";\n    &:focus {\n        border-color: ", ";\n    }\n"])), themeGet("colors.success"), themeGet("colors.success"));
export var WarningInputStyles = css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    border-color: ", ";\n    &:focus {\n        border-color: ", ";\n    }\n"], ["\n    border-color: ", ";\n    &:focus {\n        border-color: ", ";\n    }\n"])), themeGet("colors.warning"), themeGet("colors.warning"));
export var ErrorInputStyles = css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    border-color: ", ";\n    &:focus {\n        border-color: ", ";\n    }\n"], ["\n    border-color: ", ";\n    &:focus {\n        border-color: ", ";\n    }\n"])), themeGet("colors.danger"), themeGet("colors.danger"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
