var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var getSiblings = function (elem) {
    var siblings = [];
    var sibling = elem.parentNode.firstChild;
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
};
var getClosest = function (elem, selector) {
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector))
            return elem;
    }
    return null;
};
var slugify = function (text) {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
};
var createList = function (_a) {
    var list = _a.list, _b = _a.separator, separator = _b === void 0 ? "," : _b;
    if (!list)
        return;
    return list.map(function (item, index) {
        var sep;
        if (list.length !== index + 1) {
            sep = separator;
        }
        return __assign(__assign({}, item), { sep: sep });
    });
};
var flatDeep = function (arr, d) {
    if (d === void 0) { d = 1; }
    return d > 0
        ? arr.reduce(function (acc, val) {
            return acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val);
        }, [])
        : arr.slice();
};
function truncateString(str, num, dots) {
    if (dots === void 0) { dots = true; }
    var endDots = dots ? "..." : "";
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + endDots;
}
var allowedTags = [
    "p",
    "px",
    "py",
    "pt",
    "pb",
    "pl",
    "pr",
    "m",
    "mx",
    "my",
    "mt",
    "mb",
    "ml",
    "mr",
    "bg",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "lineHeight",
    "letterSpacing",
    "textAlign",
    "display",
    "justifyContetnt",
    "alignItems",
    "border",
    "borderWidth",
];
function splitVideoLink(link) {
    var video_id, video_channel;
    if (link) {
        var video_arr = link.split("=", -1);
        video_id = video_arr[1];
        video_channel = link.split(".")[1];
    }
    return { video_id: video_id, video_channel: video_channel };
}
function normalizedData(data) {
    var allContent;
    data.forEach(function (item) {
        var _a;
        var newObj = Object.entries(item).reduce(function (acc, cur) {
            var _a;
            var _b = __read(cur, 2), key = _b[0], property = _b[1];
            if (property === null) {
                return acc;
            }
            return __assign(__assign({}, acc), (_a = {}, _a[key] = property, _a));
        }, {});
        allContent = __assign(__assign({}, allContent), (_a = {}, _a[newObj["section"]] = __assign({}, newObj), _a));
    });
    return allContent;
}
function normalizedArray(data, key) {
    var content = {};
    data.forEach(function (item) {
        var _a;
        var isHasKey = hasKey(content, item[key]);
        if (!isHasKey) {
            content = __assign(__assign({}, content), (_a = {}, _a[item[key]] = [item], _a));
        }
        else {
            content[item[key]].push(item);
        }
    });
    return content;
}
function hasKey(obj, key) {
    return !!Object.prototype.hasOwnProperty.call(obj, key);
}
export { slugify, getSiblings, getClosest, createList, flatDeep, truncateString, allowedTags, splitVideoLink, normalizedData, hasKey, normalizedArray, };
