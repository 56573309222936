var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { clientsPage } from "@/Data";
import Seo from '@/Components/Seo';
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import { PageHeaderOne } from "@/Components/PageHeader";
import { BoxIconSectionTwo } from "@/Components/BoxIconSection";
import { TeamMemberSectionTwo } from "@/Components/TeamMemberSection";
import { TestimonalsSectionOne } from "@/Components/TestimonalsSection";
import ContactArea from '@/Components/ContactArea';
var Clients = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: "Clients" }), _jsx(Header, {}), _jsxs("main", __assign({ className: "site-wrapper-reveal" }, { children: [_jsx(PageHeaderOne, { data: clientsPage.pageHeader }), _jsx(TeamMemberSectionTwo, { data: clientsPage.sectionOne }), _jsx(BoxIconSectionTwo, { data: clientsPage.sectionTwo }), _jsx(TestimonalsSectionOne, { data: clientsPage.sectionThree }), _jsx(ContactArea, {})] })), _jsx(Footer, {})] }));
};
export default Clients;
