var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Image = function (_a) {
    var src = _a.src, alt = _a.alt, className = _a.className, layout = _a.layout, styles = _a.styles;
    var imageSource = require("@/Assets/Images/".concat(src)).default;
    if (typeof src === "object") {
        return (_jsx("img", { src: src.images.fallback.src, alt: alt, className: className, style: { width: layout === "constrained" ? "100%" : "auto" } }));
    }
    else {
        return (_jsx("img", { src: imageSource, alt: alt, className: className, style: __assign({ width: layout === "constrained" ? "100%" : "auto", position: 'relative' }, styles) }));
    }
};
export default Image;
