var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import styled, { css } from "styled-components";
import { typography, space, color, layout, border, themeGet, } from "@/Theme";
import { allowedTags } from "@/Utils";
import { Link } from "react-router-dom";
export var LinkStyled = css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n\n  .icon {\n    font-size: 26px;\n  }\n  ", "\n\n  &.image-btn {\n    border-radius: ", ";\n    box-shadow: ", ";\n    overflow: hidden;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n\n  .icon {\n    font-size: 26px;\n  }\n  ", "\n\n  &.image-btn {\n    border-radius: ", ";\n    box-shadow: ", ";\n    overflow: hidden;\n  }\n"])), typography, space, color, layout, border, function (props) {
    return props.$layout === "underline" && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border-bottom: 1px solid #ccc;\n    "], ["\n      border-bottom: 1px solid #ccc;\n    "])));
}, function (props) {
    return props.$hover && css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      &:hover {\n        ", "\n        ", "\n        ", "\n        ", "\n        ", "\n      }\n\n      ", "\n    "], ["\n      &:hover {\n        ", "\n        ", "\n        ", "\n        ", "\n        ", "\n      }\n\n      ", "\n    "])), function (props) {
        return props.$hover.color === "primary" && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            color: ", ";\n          "], ["\n            color: ", ";\n          "])), themeGet("colors.primary"));
    }, function (props) {
        return props.$hover.color === "secondary" && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            color: ", ";\n          "], ["\n            color: ", ";\n          "])), themeGet("colors.secondary"));
    }, function (props) {
        return props.$hover.color === "text" && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            color: ", ";\n          "], ["\n            color: ", ";\n          "])), themeGet("colors.text"));
    }, function (props) {
        return props.$hover.color === "heading" && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            color: ", ";\n          "], ["\n            color: ", ";\n          "])), themeGet("colors.heading"));
    }, function (props) {
        return props.color !== "primary" &&
            props.color !== "secondary" &&
            props.color !== "text" &&
            props.color !== "heading" && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n            color: ", ";\n          "], ["\n            color: ", ";\n          "])), props.color || themeGet("colors.primary"));
    }, function (props) {
        return props.$hover.layout === 2 && css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n          position: relative;\n          &:before {\n            transition: ", ";\n            content: \"\";\n            position: absolute;\n            bottom: -1px;\n            right: 0;\n            width: 0;\n            height: 1px;\n            ", "\n            ", "\n            ", "\n            ", "\n            ", "\n            ", "\n          }\n          &:hover:before {\n            left: 0;\n            width: 100%;\n            right: auto;\n          }\n        "], ["\n          position: relative;\n          &:before {\n            transition: ", ";\n            content: \"\";\n            position: absolute;\n            bottom: -1px;\n            right: 0;\n            width: 0;\n            height: 1px;\n            ", "\n            ", "\n            ", "\n            ", "\n            ", "\n            ", "\n          }\n          &:hover:before {\n            left: 0;\n            width: 100%;\n            right: auto;\n          }\n        "])), themeGet("transition"), function (props) {
            return props.color === "primary" && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n                background-color: ", ";\n              "], ["\n                background-color: ", ";\n              "])), themeGet("colors.primary"));
        }, function (props) {
            return props.color === "secondary" && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n                background-color: ", ";\n              "], ["\n                background-color: ", ";\n              "])), themeGet("colors.secondary"));
        }, function (props) {
            return props.color === "text" && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n                background-color: ", ";\n              "], ["\n                background-color: ", ";\n              "])), themeGet("colors.text"));
        }, function (props) {
            return props.color === "heading" && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n                background-color: ", ";\n              "], ["\n                background-color: ", ";\n              "])), themeGet("colors.heading"));
        }, function (props) {
            return props.color !== "primary" &&
                props.color !== "secondary" &&
                props.color !== "text" &&
                props.color !== "heading" && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n                background-color: ", ";\n              "], ["\n                background-color: ", ";\n              "])), props.color ||
                themeGet("colors.primary"));
        }, function (props) {
            return !props.color && css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n                background-color: ", ";\n              "], ["\n                background-color: ", ";\n              "])), themeGet("colors.primary"));
        });
    });
}, themeGet("radii.md"), themeGet("shadows.sm"));
export var StyledLink = styled(Link).withConfig({
    shouldForwardProp: function (prop) { return !__spreadArray([], __read(allowedTags), false).includes(prop); },
})(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  ", "\n  ", "\n\n  ", "\n"], ["\n  ", "\n  ", "\n\n  ", "\n"])), function (_a) {
    var $tt = _a.$tt;
    return $tt && css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n      text-transform: ", ";\n    "], ["\n      text-transform: ", ";\n    "])), $tt);
}, function (_a) {
    var $ww = _a.$ww;
    return $ww && css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      word-wrap: ", ";\n    "], ["\n      word-wrap: ", ";\n    "])), $ww);
}, LinkStyled);
export var StyledAnchor = styled.a(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  ", "\n  ", "\n\n  ", "\n"], ["\n  ", "\n  ", "\n\n  ", "\n"])), function (_a) {
    var $tt = _a.$tt;
    return $tt && css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n      text-transform: ", ";\n    "], ["\n      text-transform: ", ";\n    "])), $tt);
}, function (_a) {
    var $ww = _a.$ww;
    return $ww && css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      word-wrap: ", ";\n    "], ["\n      word-wrap: ", ";\n    "])), $ww);
}, LinkStyled);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
