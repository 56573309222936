var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import ContactForm from "@/Components/ContactForm";
import { StyledSection, LeftBox, StyledHeading, StyledDesc } from "./style";
var ContactFormArea = function () {
    return (_jsx(StyledSection, { children: _jsx(Container, { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsx(Col, __assign({ lg: 6 }, { children: _jsxs(LeftBox, { children: [_jsx(StyledHeading, __assign({ as: "h3" }, { children: "Get in Touch with Us" })), _jsxs(StyledDesc, { children: [_jsx("span", { children: "Connect" }), " with us for inquiries, support, or partnership opportunities"] })] }) })), _jsx(Col, __assign({ lg: 6 }, { children: _jsx(ContactForm, {}) }))] })) }) }));
};
export default ContactFormArea;
