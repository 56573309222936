import styled, { createGlobalStyle, css, keyframes, ThemeProvider, } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
export var breakpoints = [
    "576px",
    "768px",
    "992px",
    "1200px",
    "1600px",
    "1700px",
];
export var device = {
    small: "@media screen and (min-width: ".concat(breakpoints[0], ")"),
    medium: "@media screen and (min-width: ".concat(breakpoints[1], ")"),
    large: "@media screen and (min-width: ".concat(breakpoints[2], ")"),
    xlarge: "@media screen and (min-width: ".concat(breakpoints[3], ")"),
    xxlarge: "@media screen and (min-width: ".concat(breakpoints[4], ")"),
    xxxlarge: "@media screen and (min-width: ".concat(breakpoints[5], ")"),
};
export { createGlobalStyle, css, keyframes, ThemeProvider, themeGet };
export * from "styled-system";
export default styled;
